import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  useIonToast,
  useIonLoading,
  IonLoading,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import { ACTION_TYPES, setCartCount } from "../../redux/user/orders/actions";

import "./Orders.css";
import { trashOutline, checkmarkCircleOutline } from "ionicons/icons";
import displayRazorpay from "../../utils/PaymentGateway";
import { setMode } from "ionicons/dist/types/stencil-public-runtime";
// import PaytmChecksum from "paytmchecksum";
declare global {
  interface Window {
    Razorpay: any;
    Paytm: any;
  }
}
interface OwnProps extends RouteComponentProps {}
interface DispatchProps {}

interface PaymentsProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  getCartAction: Function;
  resetAction: Function;
  orderedData: any;
  isSetOrderSuccess: boolean;
  isSetOrderError: boolean;
  placeOrderAction: Function;
  setCartCountAction: Function;
  isPayOrderOnlineSuccess: boolean;
  isPayOrderOnlineError: boolean;
  payOrderOnlineAction: Function;
}
const Payments: React.FC<PaymentsProps> = ({
  setMenuAction,
  getCartAction,
  resetAction,
  history,
  orderedData,
  isSetOrderSuccess,
  isSetOrderError,
  setCartCountAction,
  placeOrderAction,
  isPayOrderOnlineSuccess,
  isPayOrderOnlineError,
  payOrderOnlineAction,
}) => {
  const [messageToast] = useIonToast();
  const [mode, setMode] = useState(1);
  const [showLoading, setShowLoading] = useState(false);

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };
  useIonViewWillEnter(() => {
    setMenuAction(true);
  });

  const clearCart = () => {
    getCartAction();
    resetAction();
    setCartCountAction(0);
    localStorage.setItem("cartItemCount", JSON.stringify(0));
    localStorage.removeItem("cartItems");

    presentToast("top", "Order placed successfully.");
    history.push("/dashboard", { direction: "none" });
  };

  useEffect(() => {
    if (isPayOrderOnlineSuccess) {
      clearCart();
    }
  }, [isPayOrderOnlineSuccess]);

  useEffect(() => {
    if (isSetOrderSuccess) {
      if (orderedData.orderId == 0) {
        setShowLoading(false);
        presentToast("top", "Cannot proceed. Please try again later.");
      } else if (orderedData.message == "Invalid Amount") {
        setShowLoading(false);
        presentToast("top", "Cannot proceed. Total amount is invalid.");
      } else {
        if (mode == 1) {
          // history.push("/dashboard", { direction: "none" });

          clearCart();
        } else if (mode == 2) {
          getCartAction();
          resetAction();
          setCartCountAction(0);
          localStorage.setItem("cartItemCount", JSON.stringify(0));
          localStorage.removeItem("cartItems");
          loadScript(
            "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/.js"
          );
          showPaytm();
          // showRazo rPay();
        } else if (mode == 3) {
          console.log(orderedData.sodaxoURL);
          window.location.href = orderedData.sodaxoURL;
        }
      }

      //here we have to get the orderId & send it to razor pay
      // history.push('/payments', { direction: 'none' });

      // loadScript("https://checkout.razorpay.com/v1/checkout.js");
    }
  }, [isSetOrderSuccess]);

  const loadScript = (src: any) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  //   const handleOrder = () => {
  //     getCartAction();
  //     resetAction();
  //     setCartCountAction(0);
  //     localStorage.setItem("cartItemCount", JSON.stringify(0));
  //     presentToast("top", "Order placed successfully.");
  //     console.log(history);
  //     history.push("/dashboard", { direction: "none" });
  //   };

  const handleCOD = () => {
    placeOrder(1);
  };

  async function showPaytm() {
    const res = await loadScript(
      "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/Ventur37688853180337.js"
    );

    if (!res) {
      alert("Paytm SDK failed to load. Are you online?");
      return;
    }

    var config = {
      root: "",
      flow: "DEFAULT",
      data: {
        orderId: "JSW_PROD_Order_" + orderedData.orderId /* update order id */,
        token: orderedData.paytmTxnToken /* update token value */,
        tokenType: "TXN_TOKEN",
        amount: orderedData.totalPrice /* update amount */,
      },
      handler: {
        notifyMerchant: function (eventName: any, data: any) {
          console.log("notifyMerchant handler function called");
          console.log("eventName => ", eventName);
          console.log("data => ", data);

          if (eventName == "SESSION_EXPIRED" || eventName == "APP_CLOSED") {
            history.push("/dashboard", { direction: "none" });
          }
        },
      },
    };

    console.log(config);

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
        // initialze configuration using init method
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            // alert("Loadedd.....");
            setShowLoading(false);

            // after successfully updating configuration, invoke JS Checkout
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error: any) {
            console.log("error => ", error);
            setShowLoading(false);
          });
      });
    }

    // placeOrder();
  }

  const placeOrder = (paymentMode: Number) => {
    if (localStorage.getItem("cartItems")! != null) {
      let orderDetails = {
        note: "",
        discount: 0,
        coupon: "",
        tax: "",
        counterId: 0,
        status: 1,
        orderMenus: JSON.parse(localStorage.getItem("cartItems")!),
        paymentMode: paymentMode,
      };
      placeOrderAction(orderDetails);
    } else {
      presentToast(
        "top",
        "No item in cart. Go back to Dashboard & check status."
      );
      history.push("/dashboard", { direction: "none" });
    }
  };

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {isDesktop && (
          <>
            <div className="listing-block ion-padding">
              <IonLabel className="pagename">
                <h2>Make payment</h2>
              </IonLabel>

              <IonCard className="display-card action-cover">
                <IonGrid>
                  <picture>
                    <img src="assets/img/art/payment-counter.jpg" />
                  </picture>
                </IonGrid>
                {/* <IonButton
                  onClick={() => {
                    placeOrder(1);
                    setMode(1);
                  }}
                >
                  <IonIcon src={checkmarkCircleOutline} /> Pay at Counter
                </IonButton> */}
                <IonButton
                  onClick={() => {
                    placeOrder(2);
                    setMode(2);
                    setShowLoading(true);
                  }}
                >
                  <IonIcon src={checkmarkCircleOutline} /> Pay Online
                </IonButton>

                <IonButton
                  onClick={() => {
                    placeOrder(3);
                    setMode(3);
                    setShowLoading(true);
                  }}
                >
                  <IonIcon src={checkmarkCircleOutline} /> Pay via Sodexo
                </IonButton>
              </IonCard>
            </div>
          </>
        )}

        {isMobile && (
          <>
            <div className="listing-block">
              <IonLabel className="pagename">
                <h2>Make payment</h2>
              </IonLabel>

              <IonCard className="display-card action-cover mob-card-view">
                <IonGrid>
                  <picture>
                    <img src="assets/img/art/payment-counter.jpg" />
                  </picture>
                </IonGrid>
                {/* <IonButton
                  onClick={() => {
                    placeOrder(1);
                    setMode(1);
                  }}
                >
                  <IonIcon src={checkmarkCircleOutline} /> Pay at Counter
                </IonButton> */}
                <IonButton
                  onClick={() => {
                    placeOrder(2);
                    setMode(2);
                    setShowLoading(true);
                  }}
                >
                  <IonIcon src={checkmarkCircleOutline} /> Pay Online
                </IonButton>

                <IonButton
                  onClick={() => {
                    placeOrder(3);
                    setMode(3);
                    setShowLoading(true);
                  }}
                >
                  <IonIcon src={checkmarkCircleOutline} /> Pay via Sodexo
                </IonButton>
              </IonCard>
            </div>
          </>
        )}

        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
          // duration={5000}
        />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  orderedData: state.orders.orderedData,
  isSetOrderSuccess: state.orders.isSetOrderSuccess,
  isSetOrderError: state.orders.isSetOrderError,
  isPayOrderOnlineSuccess: state.orders.isPayOrderOnlineSuccess,
  isPayOrderOnlineError: state.orders.isPayOrderOnlineError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getCartAction: () =>
      dispatch({
        type: ACTION_TYPES.GET_CART_REQUEST,
      }),
    resetAction: () =>
      dispatch({
        type: ACTION_TYPES.RESET_REQUEST,
      }),
    placeOrderAction: (orderDetails: boolean) =>
      dispatch({
        type: ACTION_TYPES.SET_ORDER_REQUEST,
        payload: orderDetails,
      }),
    setCartCountAction: (count: Number) => dispatch(setCartCount(count)),
    payOrderOnlineAction: (orderDetails: boolean) =>
      dispatch({
        type: ACTION_TYPES.PAY_ORDER_ONLINE_REQUEST,
        payload: orderDetails,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Payments));
