import React, { useEffect, useRef, useState } from "react";
import {
  useIonViewWillEnter,
  IonDatetime,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonModal,
  IonSelect,
  IonSearchbar,
  IonSelectOption,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import { trashOutline, checkmarkCircleOutline } from "ionicons/icons";
import { COUNTERHEAD_ACTION_TYPES } from "../../redux/user/counterhead/actions";
import moment from "moment";
import BarGraph from "../../components/Charts/BarGraph/BarGraph";
import LineChart from "../../components/Charts/LineChart/LineChart";
import { useDownloadExcel } from "react-export-table-to-excel";

interface OwnProps {}

interface DispatchProps {}

interface ReportsByDailySubsidyProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  getReportSubsidyAction: Function;
  isgetDailySaleSubsidyReportsSuccess: boolean;
  isgetDailySaleSubsidyReportsError: boolean;
  dailySaleSubsidyReportData: [];

  getMenuWiseReportsAction: Function;
  isgetMenuWiseDaySaleReportsSuccess: boolean;
  isgetMenuWiseDaySaleReportsError: boolean;
  menuWiseReportData: [];
}
const ReportsByDailySubsidy: React.FC<ReportsByDailySubsidyProps> = ({
  setMenuAction,
  getReportSubsidyAction,
  isgetDailySaleSubsidyReportsSuccess,
  isgetDailySaleSubsidyReportsError,
  dailySaleSubsidyReportData,

  getMenuWiseReportsAction,
  isgetMenuWiseDaySaleReportsSuccess,
  isgetMenuWiseDaySaleReportsError,
  menuWiseReportData,
}) => {
  const tableRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
    empSubsidy: 1,
  });

  useIonViewWillEnter(() => {
    setMenuAction(true);
    getReportSubsidyAction(data);
  });
  useEffect(() => {
    if (isgetDailySaleSubsidyReportsSuccess) {
    }
  }, [isgetDailySaleSubsidyReportsSuccess]);

  useEffect(() => {
    if (isgetMenuWiseDaySaleReportsSuccess) {
    }
  }, [isgetMenuWiseDaySaleReportsSuccess]);

  const onChange = (valueEvent: any, nameEvent: any, val = null) => {
    let value = val || valueEvent;
    let name = nameEvent;
    if (name == "toDate") {
      value = moment(value).add(1, "days").format("YYYY-MM-DD");
    }
    setData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleDateChange = (e: any) => {
    onChange(e.target.value, e.target.name);
  };
  const handleReports = () => {
    getReportSubsidyAction({
      fromDate: data.fromDate,
      toDate: data.toDate,
      empSubsidy: data.empSubsidy,
    });
  };

  const handleMenuWise = (selectedReport: any) => {
    getMenuWiseReportsAction({ fromDate: selectedReport.createdOn });
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        <div className="listing-block">
          <IonLabel className="pagename">
            <h2>Report By Daily Subsidy</h2>
          </IonLabel>
          <div className="filters-row">
            <IonGrid>
              <IonRow></IonRow>
            </IonGrid>
          </div>

          <IonCard className="table-layout">
            <IonCardContent>
              <IonRow>
                <IonCol size="3" size-md="3" style={{ marginTop: 16 }}>
                  <label style={{ margin: ".4rem 0" }} htmlFor="start">
                    Start date :{" "}
                  </label>

                  <input
                    type="date"
                    id="from"
                    name="fromDate"
                    onChange={(e) => handleDateChange(e)}
                    // min="2018-01-01" max="2018-12-31"
                  />
                </IonCol>

                <IonCol size="3" size-md="3" style={{ marginTop: 16 }}>
                  <label htmlFor="start" style={{ margin: ".4rem 0" }}>
                    End date :{" "}
                  </label>
                  <input
                    type="date"
                    id="to"
                    name="toDate"
                    onChange={(e) => handleDateChange(e)}
                  />
                </IonCol>

                <IonCol size="3" size-md="3" style={{ marginTop: 16 }}>
                  <label style={{ margin: ".4rem 0" }} htmlFor="start">
                    Subsidy
                  </label>
                  <br />
                  <select
                    className="input-field"
                    name="empSubsidy"
                    value={data.empSubsidy}
                    onChange={(e) => onChange(e.target.value, e.target.name)}
                  >
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </select>
                </IonCol>

                <IonCol size="3" size-md="3">
                  <IonButton onClick={() => handleReports()}>
                    Get Reports
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" size-md="12">
                  <div className="table-wrapper">
                    {dailySaleSubsidyReportData.length > 0 && (
                      <div>
                        {/* <button onClick={onDownload}> Export excel </button> */}

                        <table ref={tableRef}>
                          <tr>
                            <th>Delivered Count</th>
                            <th>Order Created</th>
                            <th>Total Transactions</th>
                            <th></th>
                          </tr>

                          {dailySaleSubsidyReportData.map(
                            (report: any, index: Number) => {
                              return (
                                <>
                                  <tr>
                                    <td>{report.deliveredCount}</td>
                                    <td>{report.createdOn}</td>
                                    <td>{report.totalPrice}</td>
                                    <th>
                                      <a
                                        onClick={() => {
                                          setIsOpen(true);
                                          handleMenuWise(report);
                                        }}
                                      >
                                        View
                                      </a>
                                    </th>
                                  </tr>
                                </>
                              );
                            }
                          )}
                        </table>
                      </div>
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </IonCardContent>

            <IonModal
              isOpen={isOpen}
              backdropDismiss={false}
              className="large-modal-custom"
            >
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="start">
                    <IonButton onClick={() => setIsOpen(false)}>
                      Close
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent className="ion-padding">
                <BarGraph menuWiseReportData={menuWiseReportData}></BarGraph>

                <div className="table-layout">
                  <div className="table-wrapper">
                    {dailySaleSubsidyReportData.length > 0 && (
                      <table>
                        <tr>
                          <th>Menu Name</th>
                          <th>Quantity</th>
                          <th>Total Price</th>
                        </tr>

                        {menuWiseReportData.map((menu: any, index: Number) => {
                          return (
                            <>
                              <tr>
                                <td>{menu.menuName}</td>
                                <td>{menu.quantity}</td>
                                <td>{menu.totalPrice}</td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    )}
                  </div>
                </div>
              </IonContent>
            </IonModal>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isgetDailySaleSubsidyReportsSuccess:
    state.counterheadData.isgetDailySaleSubsidyReportsSuccess,
  isgetDailySaleSubsidyReportsError:
    state.counterheadData.isgetDailySaleSubsidyReportsError,
  dailySaleSubsidyReportData: state.counterheadData.dailySaleSubsidyReportData,

  isgetMenuWiseDaySaleReportsSuccess:
    state.counterheadData.isgetMenuWiseDaySaleReportsSuccess,
  isgetMenuWiseDaySaleReportsError:
    state.counterheadData.isgetMenuWiseDaySaleReportsError,
  menuWiseReportData: state.counterheadData.menuWiseReportData,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getReportSubsidyAction: (dates: any) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_DAILY_SALE_SUBSIDY_REPORT_REQUEST,
        payload: dates,
      }),

    getMenuWiseReportsAction: (fromDate: any) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_MENU_WISE_REPORTS_REQUEST,
        payload: fromDate,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsByDailySubsidy);
