import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButton,
  IonContent,
  IonPopover,
  IonLabel,
  IonList,
  IonItem,
} from "@ionic/react";
import {
  notificationsCircleOutline,
  ellipsisVerticalSharp,
  notificationsOutline,
  cartOutline,
  logOutOutline,
} from "ionicons/icons";
import "./Header.css";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { RouteComponentProps, withRouter, useLocation } from "react-router";
import { BrowserView, MobileView } from "react-device-detect";
import { ACTION_TYPES } from "../redux/user/orders/actions";

interface ContainerProps extends RouteComponentProps {
  cartCount: any;
  getCartAction: Function;
  isCartGetError: boolean;
  cartItemsData: any;
  isCartGetSuccess: boolean;
}
const AuthHeader: React.FC<ContainerProps> = ({
  cartCount,
  history,
  getCartAction,
  isCartGetError,
  isCartGetSuccess,
  cartItemsData,
}) => {
  const [cartitemCount, setcartitemCount] = useState(0);
  const [userDetails, setuserDetails] = useState({
    firstname: "",
    lastname: "",
    role: "",
    userId: 0,
  });
  const [showPopover, setshowPopover] = useState(false);

  useEffect(() => {
    getCartAction();
    if (localStorage.getItem("userDetails") != null) {
      let userDetails = JSON.parse(localStorage.getItem("userDetails")!);
      setuserDetails(userDetails);
    }
  }, []);

  useEffect(() => {
    if (isCartGetSuccess) {
      if (cartItemsData?.cartList.length > 0) {
        localStorage.setItem(
          "cartItemCount",
          JSON.stringify(cartItemsData?.cartList.length)
        );

        setcartitemCount(cartItemsData?.cartList.length);
      } else {
        localStorage.setItem("cartItemCount", JSON.stringify(0));

        setcartitemCount(0);
      }
    }
  }, [isCartGetSuccess]);

  useEffect(() => {
    // console.log(JSON.parse(localStorage.getItem("cartItemCount")!));

    // JSON.parse(localStorage.getItem("cartItemCount")!) == null
    //   ? 0
    //   : JSON.parse(localStorage.getItem("cartItemCount")!);

    setcartitemCount(cartCount);
  }, [cartCount]);

  const navigateToCart = () => {
    history.push("/cart");
  };

  const logout = () => {
    history.push("/signin");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <IonHeader>
      <BrowserView>
        <IonToolbar>
          <div className="menu-listing-nav">
            {userDetails.role == "User" && (
              <div className="col-left">
                <span
                  className="notification cart"
                  onClick={() => navigateToCart()}
                >
                  <IonIcon src={cartOutline} size="large" />
                  <span className="cart-count">
                    {cartitemCount} <small>item</small>
                  </span>
                </span>
              </div>
            )}

            <div className="col-right">
              <span className="username">
                {userDetails.firstname} {userDetails.lastname}
              </span>
              <br />
              <span className="userid">User ID: VP{userDetails.userId}</span>
              <span className="dropdown">
                <IonButton
                  fill="clear"
                  id="click-trigger"
                  onClick={() => logout()}
                >
                  <IonIcon src={logOutOutline} />
                </IonButton>
              </span>
            </div>
          </div>
        </IonToolbar>
      </BrowserView>

      <MobileView>
        <div className="mob-header">
          <picture className="app-header-logo">
            <img src="assets/img/logo.png" alt="Ionic logo" />
          </picture>
          <span className="dropdown">
            <IonButton fill="clear" id="click-trigger" onClick={() => logout()}>
              <IonIcon src={logOutOutline} />
            </IonButton>
            {userDetails.role == "User" && (
              <IonButton
                fill="clear"
                className="cart-count"
                onClick={() => navigateToCart()}
              >
                <IonIcon src={cartOutline} />
                {cartitemCount}
              </IonButton>
            )}
          </span>
        </div>
      </MobileView>
    </IonHeader>
  );
};

const mapStateToProps = (state: any) => ({
  cartCount: state.orders.cartCount,
  cartItemsData: state.orders.cartItem,
  isCartGetSuccess: state.orders.isCartGetSuccess,
  isCartGetError: state.orders.isCartGetError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    getCartAction: () =>
      dispatch({
        type: ACTION_TYPES.GET_CART_REQUEST,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuthHeader));
