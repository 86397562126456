import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  useIonViewWillEnter,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonBadge,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { COUNTERHEAD_ACTION_TYPES } from "../../redux/user/counterhead/actions";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import {
  trashOutline,
  checkmarkCircleOutline,
  calendar,
  informationCircle,
  map,
  eyeOutline,
} from "ionicons/icons";
import moment from "moment";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface OrderRequestReceivedProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  getIncomingOrderAction: Function;
  isIncomingOrderSuccess: boolean;
  isIncomingOrderError: boolean;
  incomingorderData: any;
  acceptOrderAction: Function;
  rejectOrderAction: Function;
  isOrderAcceptedSuccess: boolean;
  isOrderAcceptedError: boolean;
  isOrderRejectedSuccess: boolean;
  isOrderRejectedError: boolean;
}
const OrderRequestReceived: React.FC<OrderRequestReceivedProps> = ({
  setMenuAction,
  history,
  getIncomingOrderAction,
  isIncomingOrderSuccess,
  isIncomingOrderError,
  incomingorderData,
  acceptOrderAction,
  rejectOrderAction,
  isOrderAcceptedSuccess,
  isOrderAcceptedError,
  isOrderRejectedSuccess,
  isOrderRejectedError,
}) => {
  const [presentAlert] = useIonAlert();
  const [messageToast] = useIonToast();
  const [orderListData, setorderListData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenu, setselectedMenu] = useState([
    {
      menuDetails: { image_link: "", price: 0, menuName: "" },
      quantity: 0,
      subMenuDetails: { image_link: "", price: 0, subCategoryName: "" },
    },
  ]);

  const [orderDetails, setOrderDetails] = useState({
    orderId: "",
    discount: 0,
  });

  const [productTotal, setproductTotal] = useState(0);
  const [filterOrderId, setFilterOrderId] = useState(0);

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };

  useIonViewWillEnter(() => {
    setMenuAction(true);
    getIncomingOrderAction(1);
  });

  useEffect(() => {
    if (isIncomingOrderSuccess) {
      setorderListData(incomingorderData.responses);
    }
  }, [isIncomingOrderSuccess]);

  useEffect(() => {
    if (isOrderAcceptedSuccess) {
      presentToast("top", "Order Accepted successfully.");
      getIncomingOrderAction(1);
    }
  }, [isOrderAcceptedSuccess]);

  useEffect(() => {
    if (isOrderAcceptedError) {
      presentToast("top", "Order cannot be accepted.");
    }
  }, [isOrderAcceptedError]);

  useEffect(() => {
    if (isOrderRejectedError) {
      presentToast("top", "Order cannot be rejected.");
    }
  }, [isOrderRejectedError]);

  useEffect(() => {
    if (isOrderRejectedSuccess) {
      presentToast("top", "Order Rejected successfully.");
      getIncomingOrderAction(1);

      // history.push('/counter/orders-inprogress');
    }
  }, [isOrderRejectedSuccess]);

  const handleRejected = (selectedOrder: any) => {
    presentAlert({
      header: "Are you sure?",
      cssClass: "custom-alert",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "OK",
          role: "confirm",
          handler: () => {
            rejectOrderAction(selectedOrder.orderId);
          },
        },
      ],
    });
  };

  const handleAccept = (selectedOrder: any) => {
    presentAlert({
      header: "Estimate time",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "OK",
          role: "confirm",
          handler: (data) => {
            let acceptData = {
              orderId: selectedOrder.orderId,
              expectedTime: data,
            };
            acceptOrderAction(acceptData);
          },
        },
      ],

      inputs: [
        {
          label: "5 minutes",
          type: "radio",
          value: "5",
        },
        {
          label: "10 minutes",
          type: "radio",
          value: "10",
        },
        {
          label: "15 minutes",
          type: "radio",
          value: "15",
        },
      ],
    });
  };

  const getProductTotal = (productTotalCal: any, discount: number) => {
    console.log(productTotalCal);
    console.log(discount);

    if (discount > 0) {
      let afterDiscount = productTotalCal - (productTotalCal * discount) / 100;
      setproductTotal(afterDiscount);
    } else {
      setproductTotal(productTotalCal);
    }
  };

  const handleChange = (e: any) => {
    if (e.target.value.trim().length > 0 && e.target.value.trim().length < 10) {
      setFilterOrderId(e.target.value.trim());
    } else if (e.target.value.trim().length == 0) {
      setFilterOrderId(0);
    }
  };

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop &&
                    <> */}

        <div className="listing-block">
          <IonRow>
            <IonCol size="6" size-md="6">
              <IonLabel className="pagename">
                <h2>Orders Received</h2>
              </IonLabel>
            </IonCol>

            <IonCol style={{ marginTop: 10 }}>
              <input
                type="text"
                placeholder="Search by order id"
                className="input-field input-field-full float-right"
                onChange={(e) => handleChange(e)}
                maxLength={10}
              />
            </IonCol>
          </IonRow>

          <IonCard className="table-layout">
            <IonCardContent>
              <IonRow>
                <IonCol size="12" size-md="12">
                  <div className="table-wrapper">
                    <table id="order-received">
                      <tbody>
                        <tr>
                          <th>Order ID</th>
                          <th>Menu Details</th>
                          {/* <th>Menu</th> */}
                          <th>User ID</th>
                          <th>Time</th>
                          <th>Payment Mode</th>
                          <th>Subsidy Order</th>
                          <th></th>
                        </tr>

                        {orderListData.length > 0 &&
                          orderListData
                            .filter(
                              (order: any) =>
                                filterOrderId != 0
                                  ? order.orderId
                                      .toString()
                                      .includes(filterOrderId)
                                  : order
                              // order
                            )
                            .map((order: any, index: Number) => {
                              return (
                                <>
                                  <tr>
                                    <td>{order.orderId}</td>
                                    <td>
                                      <a
                                        onClick={() => {
                                          setselectedMenu(
                                            order.orderMenuResponse
                                          );
                                          setOrderDetails(order);
                                          setIsOpen(true);
                                          // setTimeout(() => {
                                          //   window.print();
                                          // }, 1000);
                                          let productTotalCal = 0;
                                          order.orderMenuResponse?.forEach(
                                            (elm: any) => {
                                              productTotalCal +=
                                                (elm.menuDetails
                                                  ? elm.menuDetails.price
                                                  : elm.subMenuDetails.price) *
                                                elm.quantity;
                                            }
                                          );

                                          getProductTotal(
                                            productTotalCal,
                                            order.discount
                                          );
                                        }}
                                      >
                                        View
                                      </a>
                                    </td>
                                    <td>{order.createdBy}</td>
                                    <td>
                                      {moment(order.createdOn).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {order.paymentMode == 1
                                        ? "PAID AT COUNTER"
                                        : order.paymentMode == 2
                                        ? "PAID ONLINE"
                                        : order.paymentMode == 3
                                        ? "PAID VIA SODEXO"
                                        : "-"}
                                    </td>
                                    <td>{order.discount > 0 ? "YES" : "NO"}</td>
                                    <td>
                                      <IonButton
                                        color="secondary"
                                        onClick={() => handleAccept(order)}
                                      >
                                        Accept
                                      </IonButton>
                                      <IonButton
                                        color="primary"
                                        onClick={() => handleRejected(order)}
                                      >
                                        Reject
                                      </IonButton>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        </div>

        <IonModal
          isOpen={isOpen}
          backdropDismiss={false}
          className="large-modal-custom print-bill-popup"
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton
                  onClick={() => setIsOpen(false)}
                  className="menu-btn-close"
                >
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              {selectedMenu.length > 0 && (
                <div className="ion-padding">
                  <table className="menu-title-print">
                    <tr>
                      <th>VENTURE PROFESSIONALS</th>
                    </tr>
                    <tr>
                      <td> {moment().format("DD/MM/YYYY hh:mm:ss")}</td>
                    </tr>
                    <tr>
                      <td>VP - {orderDetails.orderId}</td>
                    </tr>
                  </table>

                  <table className="menu-item-print">
                    <tr>
                      <th>Item</th>
                      <th>Price</th>
                      <th>Quantity</th>
                    </tr>

                    {selectedMenu.map((menu, index) => {
                      return (
                        <tr>
                          <td>
                            {" "}
                            {menu.menuDetails
                              ? menu.menuDetails.menuName
                              : menu.subMenuDetails.subCategoryName}
                          </td>
                          <td>
                            INR.{" "}
                            {menu.menuDetails
                              ? menu.menuDetails.price
                              : menu.subMenuDetails.price}
                          </td>
                          <td>{menu.quantity} unit</td>
                        </tr>
                      );
                    })}
                  </table>

                  <table className="menu-total-print">
                    <tr>
                      <th>Total</th>
                      <th>
                        INR. {productTotal}{" "}
                        {orderDetails.discount > 0 && "Subsidy Applied"}
                      </th>
                    </tr>

                    {/* <tr>
                      <td>Discount</td>
                      <td></td>
                      <td>INR. </td>
                    </tr>

                    <tr>
                      <td>Net payable</td>
                      <td></td>
                      <td>INR. </td>
                    </tr> */}
                  </table>

                  <table className="menu-title-print">
                    <tr>
                      <td>Thank you for ordering food.</td>
                    </tr>
                  </table>

                  <table className="menu-btn-print">
                    <tr>
                      <td>
                        <button onClick={() => window.print()}> Print</button>
                      </td>
                    </tr>
                  </table>
                </div>
              )}
            </IonList>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isIncomingOrderSuccess: state.counterheadData.isIncomingOrderSuccess,
  isIncomingOrderError: state.counterheadData.isIncomingOrderError,
  incomingorderData: state.counterheadData.incomingorderData,
  isOrderAcceptedSuccess: state.counterheadData.isOrderAcceptedSuccess,
  isOrderAcceptedError: state.counterheadData.isOrderAcceptedError,
  isOrderRejectedSuccess: state.counterheadData.isOrderRejectedSuccess,
  isOrderRejectedError: state.counterheadData.isOrderRejectedError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getIncomingOrderAction: (status: Number) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_INCOMING_ORDER_REQUEST,
        status: status,
      }),
    acceptOrderAction: (acceptData: any) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.ACCEPT_ORDER_REQUEST,
        payload: acceptData,
      }),
    rejectOrderAction: (selectedOrderId: any) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.REJECT_ORDER_REQUEST,
        payload: selectedOrderId,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderRequestReceived));
