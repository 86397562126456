import React, { useEffect, useRef, useState } from "react";
import {
  useIonViewWillEnter,
  useIonToast,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonGrid,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonThumbnail,
  IonIcon,
  IonAccordion,
  IonAccordionGroup,
  IonModal,
} from "@ionic/react";
import { ACTION_TYPES, setCartCount } from "../../redux/user/orders/actions";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ellipsisVerticalSharp,
  notificationsCircleOutline,
  checkmarkCircleOutline,
  trashOutline,
  cartOutline,
} from "ionicons/icons";
import "./Orders.css";
import AuthHeader from "../../components/AuthHeader";
import { timeOutline } from "ionicons/icons";
import { isDesktop, isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonToggle } from '@ionic/react';

import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
interface OwnProps {}

interface DispatchProps {}
interface MenuProps {
  menuName: "";
  subMenuDetails: [
    {
      type: string;
      subCategoryName: "";
      price: 0;
      image_link: "";
      isIncludedInPackage: null;
      status: null;
    }
  ];
  availableQty: "";
  price: 0;
  mdId: 0;
  image_link: "";
  isIncludedInPackage: null;
  status: null;
}

interface MenuListProps {
  resetAction: Function;
  setCartCountAction: Function;
  getMenuListAction: Function;
  setMenuAction: Function;
  addCartAction: Function;
  isMenuSuccess: boolean;
  isMenuError: boolean;
  menuData: Array<MenuProps>;
  isCartAddedSuccess: boolean;
  isCartAddedError: boolean;
}
const MenuList: React.FC<MenuListProps> = ({
  resetAction,
  setCartCountAction,
  getMenuListAction,
  setMenuAction,
  addCartAction,
  isMenuSuccess,
  isMenuError,
  menuData,
  isCartAddedSuccess,
  isCartAddedError,
}) => {
  const [messageToast] = useIonToast();

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };
  const [isModalOpen, setisModalOpen] = useState(false);
  const [selectedMenu, setselectedMenu] = useState({
    subMenuDetails: [
      {
        image_link: "",
        price: 0,
        subCategoryName: "",
        isIncludedInPackage: null,
        status: null,
      },
    ],
    menuName: "",
    price: 0,
  });

  const orderedList = [
    {
      menuName: "Lunch Meal",
      subMenuDetails: [
        { subCategoryName: "Roti", price: "" },
        { subCategoryName: "Rice", price: "" },
        { subCategoryName: "Dal", price: "" },
        { subCategoryName: "Salad", price: "" },
        { subCategoryName: "Mix Veg", price: "" },
        { subCategoryName: "Curd", price: "" },
      ],
      availableQty: 3,
      price: "80",
      image_link: "",
      statusHistory: [
        {
          status: "Order accepted",
          desc: "Anil J. accepted your order",
          progress: "1",
        },
        {
          status: "Order ready",
          desc: "Your order is ready at pick up counter",
          progress: "1",
        },
        { status: "Order delivered", desc: "Order Delivered", progress: "0" },
      ],
    },
  ];
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 0.01);
    }, 50);

    return () => clearInterval(interval);
  }, []);

  if (progress > 1) {
    setTimeout(() => {
      setProgress(0);
    }, 1000);
  }

  useEffect(() => {
    setMenuAction(true);
    getMenuListAction();
  }, []);

  useEffect(() => {
    if (isCartAddedSuccess) {
      presentToast("top", "Item added to cart successfully.");
      if (localStorage.getItem("cartItemCount") == null) {
        localStorage.setItem("cartItemCount", JSON.stringify(1));
        setCartCountAction(1);
      } else {
        let newCount = JSON.parse(localStorage.getItem("cartItemCount")!) + 1;

        localStorage.setItem("cartItemCount", JSON.stringify(newCount));

        setCartCountAction(newCount);
      }
    }
  }, [isCartAddedSuccess]);

  useEffect(() => {
    if (isCartAddedError) {
      presentToast("top", "Cannot add item , Please try again later.");
    }
  }, [isCartAddedError]);

  const addToCart = (item: any, type: String) => {
    let cartItemData = {
      counterId: item.counterId,
      status: item.status,
      menuId: type == "Menu" ? item.menuId : item.subMenuId,
      quantity: 1,
    };
    addCartAction(cartItemData);
  };
  const checkMenuDetails = (item: any) => {
    setselectedMenu(item);
    setisModalOpen(true);
  };

  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  const accordionGroupChange = (e: any) => {};

  return (
    <IonPage>
      <AuthHeader></AuthHeader>

      <IonContent className="ion-padding">
        {isDesktop && (
          <>
            <div className="listing-block ion-padding">
              <IonLabel className="pagename">
                <strong>Food type - Pure Veg.</strong>
                <IonToggle className="toggle-menue-type"></IonToggle>
              </IonLabel>

              <IonLabel className="pagename">
                <h2>Company Packs</h2>
              </IonLabel>

              <IonList>
                {menuData.length > 0 &&
                  menuData.map((menu, index) => {
                    return (
                      <>
                        {menu.isIncludedInPackage == 1 && menu.status == 1 && (
                          <>
                            <IonLabel className="meal-name">
                              <h2>{menu.menuName}</h2>
                            </IonLabel>
                            <IonCard className="display-card">
                              <IonCardContent>
                                <IonItem lines="none">
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol
                                        size="4"
                                        size-md="4"
                                        className="product-image-block"
                                      >
                                        <img
                                          alt=""
                                          width={300}
                                          height={180}
                                          src={
                                            menu.image_link
                                              ? menu.image_link
                                              : "assets/img/menu/default.png"
                                          }
                                        />
                                      </IonCol>

                                      <IonCol
                                        size="5"
                                        size-md="5"
                                        className="product-detail-block"
                                      >
                                        <IonLabel>
                                          <h3>Ingredients of meal</h3>
                                          <div>
                                            <ul className="ingredients-list">
                                              {menu.subMenuDetails
                                                .sort(
                                                  (a: any, b: any) =>
                                                    a.smdId - b.smdId
                                                )
                                                .map((item) => {
                                                  return (
                                                    <>
                                                      {item.isIncludedInPackage ? (
                                                        <li>
                                                          {item.subCategoryName}
                                                        </li>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  );
                                                })}
                                            </ul>
                                          </div>
                                          <div className="product-info">
                                            <IonRow>
                                              <IonCol
                                                size="6"
                                                size-md="6"
                                                className="product-detail-block"
                                              >
                                                <strong>Quantity</strong>: 1
                                                {/* {menu.availableQty} */}
                                              </IonCol>
                                              <IonCol
                                                size="6"
                                                size-md="6"
                                                className="product-detail-block"
                                              >
                                                <strong>Price</strong>:{" "}
                                                {menu.price}
                                              </IonCol>
                                            </IonRow>
                                          </div>
                                        </IonLabel>
                                      </IonCol>
                                      {/* 
                                      <IonCol
                                        size="3"
                                        size-md="3"
                                        className="product-action-block"
                                      >
                                        <IonButton
                                          onClick={() =>
                                            addToCart(menu, "Menu")
                                          }
                                        >
                                          <IonIcon src={cartOutline} />
                                          Add to cart
                                        </IonButton>
                                        <p>You can place order after 11 am</p>
                                      </IonCol> */}

                                      <IonIcon
                                        src={ellipsisVerticalSharp}
                                        className="product-dropdown"
                                      />
                                    </IonRow>
                                  </IonGrid>
                                </IonItem>
                              </IonCardContent>
                            </IonCard>
                          </>
                        )}
                      </>
                    );
                  })}
              </IonList>

              <IonLabel className="pagename">
                <h2>Cash &amp; Carry</h2>
              </IonLabel>

              {menuData.length > 0 &&
                menuData.map((menu, index) => {
                  return (
                    <IonAccordionGroup onIonChange={accordionGroupChange}>
                      {menu.status == 1 && (
                        <IonAccordion value={menu.menuName} key={index}>
                          <IonItem slot="header" color="light">
                            <IonLabel className="meal-name">
                              {menu.menuName}
                            </IonLabel>
                          </IonItem>

                          <div className="ion-padding" slot="content">
                            <IonList>
                              {menu.subMenuDetails
                                .sort((a: any, b: any) => a.smdId - b.smdId)
                                .map((item, subMenuIndex) => {
                                  return (
                                    <>
                                      {item.status == 1 && (
                                        <div className="item-card">
                                          <div className="item-image">
                                            <img
                                              alt=""
                                              src={
                                                item.image_link
                                                  ? item.image_link
                                                  : "assets/img/menu/default.png"
                                              }
                                            />
                                          </div>
                                          <div className="item-description">
                                            <h5>
                                              {item.subCategoryName}{" "}
                                              {item?.type === "2" ? (
                                                <span className="food-non-veg">
                                                  <span className="fill-space"></span>
                                                </span>
                                              ) : (
                                                <span className="food-veg">
                                                  <span className="fill-space"></span>
                                                </span>
                                              )}
                                            </h5>
                                            <p>INR. {item.price}</p>
                                            <a
                                              onClick={() =>
                                                addToCart(item, "SubMenu")
                                              }
                                            >
                                              Add to Cart
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                            </IonList>
                          </div>
                        </IonAccordion>
                      )}
                    </IonAccordionGroup>
                  );
                })}
            </div>
          </>
        )}

        {isMobile && (
          <>
            <div className="listing-block">
              <IonToggle className="toggle-menue-type">Pure Veg.</IonToggle>

              <IonLabel className="pagename">
                <h2>Company Packs</h2>
              </IonLabel>

              <IonList>
                <IonRow>
                  {menuData.length > 0 &&
                    menuData.map((menu, index) => {
                      return (
                        <>
                          {menu.isIncludedInPackage == 1 &&
                            menu.status == 1 && (
                              <IonCol size="6" size-md="6">
                                <IonCard className="display-card">
                                  <IonCardContent>
                                    <div className="product-listing-mob">
                                      <div>
                                        <img
                                          alt=""
                                          src={
                                            menu.image_link
                                              ? menu.image_link
                                              : "assets/img/menu/default.png"
                                          }
                                        />
                                      </div>
                                      <div>
                                        <IonLabel>
                                          <h2>{menu.menuName}</h2>
                                          <p>
                                            <strong>Price</strong>: {menu.price}
                                          </p>
                                        </IonLabel>
                                      </div>
                                      {/* <div className="text-center">
                                        <IonButton
                                          expand="block"
                                          onClick={() =>
                                            addToCart(menu, "Menu")
                                          }
                                        >
                                          <IonIcon src={cartOutline} />
                                          Add to cart
                                        </IonButton>
                                        <p>
                                          You can place order
                                          <br /> after 11:00 am
                                        </p>
                                      </div> */}
                                      <div className="text-center">
                                        <IonButton
                                          expand="block"
                                          onClick={() => checkMenuDetails(menu)}
                                        >
                                          <IonIcon src={cartOutline} />
                                          Check Menu
                                        </IonButton>
                                      </div>
                                    </div>
                                  </IonCardContent>
                                </IonCard>
                              </IonCol>
                            )}
                        </>
                      );
                    })}
                </IonRow>
              </IonList>

              <IonLabel className="pagename">
                <h2>Cash &amp; Carry</h2>
              </IonLabel>

              {menuData.length > 0 &&
                menuData.map((menu, index) => {
                  return (
                    <IonAccordionGroup>
                      {menu.status == 1 && (
                        <IonCard>
                          <IonAccordion value={menu.menuName} key={index}>
                            <IonItem slot="header" color="light">
                              <IonLabel className="meal-name">
                                {menu.menuName}
                              </IonLabel>
                            </IonItem>

                            <div className="ion-padding" slot="content">
                              {menu.subMenuDetails
                                .sort((a: any, b: any) => a.smdId - b.smdId)
                                .map((item, subMenuIndex) => {
                                  return (
                                    <>
                                      {item.status == 1 && (
                                        <div
                                          className="item-card"
                                          key={subMenuIndex}
                                        >
                                          <div className="item-image">
                                            <img
                                              alt=""
                                              src={
                                                item.image_link
                                                  ? item.image_link
                                                  : "assets/img/menu/default.png"
                                              }
                                            />
                                          </div>
                                          <div className="item-description">
                                            <h5>{item.subCategoryName}</h5>
                                            <p>INR. {item.price}</p>
                                            <a
                                              onClick={() =>
                                                addToCart(item, "SubMenu")
                                              }
                                            >
                                              Add to Cart
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                            </div>
                          </IonAccordion>
                        </IonCard>
                      )}
                    </IonAccordionGroup>
                  );
                })}
            </div>

            <IonModal
              isOpen={isModalOpen}
              backdropDismiss={false}
              className="large-modal-custom"
            >
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="end">
                    <IonButton onClick={() => setisModalOpen(false)}>
                      Close
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>

              <IonContent className="ion-padding">
                <IonList>
                  {selectedMenu.subMenuDetails != null && (
                    <div className="listing-block detail-block ion-padding">
                      <IonLabel className="pagename">
                        <h2>Menu Detailsassadasd:</h2>
                      </IonLabel>

                      <IonCard className="display-card cart-view">
                        <IonCardContent>
                          <IonItem lines="none">
                            <IonGrid>
                              <IonRow className="cart-header">
                                <IonCol size="12" size-md="12">
                                  <strong>Menu</strong>: {selectedMenu.menuName}
                                </IonCol>

                                <IonCol size="12" size-md="12">
                                  <strong>Price</strong>: INR.{" "}
                                  {selectedMenu.price}
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonItem>

                          <IonItem lines="none">
                            <IonGrid>
                              <IonRow className="cart-header">
                                <IonCol className="product-detail-block">
                                  Menu
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonItem>

                          <IonItem lines="none">
                            <IonGrid>
                              {selectedMenu.subMenuDetails
                                .sort((a: any, b: any) => a.smdId - b.smdId)
                                .map((item, subMenuIndex) => {
                                  return (
                                    <>
                                      {item.isIncludedInPackage == 1 &&
                                        item.status == 1 && (
                                          <IonRow>
                                            <IonCol>
                                              {item.subCategoryName}
                                            </IonCol>
                                          </IonRow>
                                        )}
                                    </>
                                  );
                                })}
                            </IonGrid>
                          </IonItem>
                        </IonCardContent>
                      </IonCard>
                    </div>
                  )}
                </IonList>
              </IonContent>
            </IonModal>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  menuData: state.orders.menuData,
  isMenuSuccess: state.orders.isMenuSuccess,
  isMenuError: state.orders.isMenuError,
  orderedData: state.orders.orderedData,
  isSetOrderSuccess: state.orders.isSetOrderSuccess,
  isSetOrderError: state.orders.isSetOrderError,

  isCartAddedSuccess: state.orders.isCartAddedSuccess,
  isCartAddedError: state.orders.isCartAddedError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    getMenuListAction: () =>
      dispatch({
        type: ACTION_TYPES.GET_MENU_REQUEST,
      }),
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    placeOrderAction: (orderDetails: boolean) =>
      dispatch({
        type: ACTION_TYPES.SET_ORDER_REQUEST,
        payload: orderDetails,
      }),
    setCartCountAction: (count: Number) => dispatch(setCartCount(count)),
    addCartAction: (cartItems: any) =>
      dispatch({
        type: ACTION_TYPES.ADD_TO_CART_REQUEST,
        payload: cartItems,
      }),
    resetAction: () =>
      dispatch({
        type: ACTION_TYPES.RESET_REQUEST,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuList);
