import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonBadge,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonAlert,
  useIonToast,
  IonModal,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import {
  trashOutline,
  checkmarkCircleOutline,
  calendar,
  informationCircle,
  map,
  eyeOutline,
} from "ionicons/icons";
import { COUNTERHEAD_ACTION_TYPES } from "../../redux/user/counterhead/actions";
import moment from "moment";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface OrderRequestDeliveredProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  readyOrderAction: Function;
  getDeliveredOrderAction: Function;
  isDeliveredOrderSuccess: boolean;
  isDeliveredOrderError: boolean;
  deliveredorderData: any;
}
const OrderRequestDelivered: React.FC<OrderRequestDeliveredProps> = ({
  setMenuAction,
  history,
  getDeliveredOrderAction,
  isDeliveredOrderSuccess,
  isDeliveredOrderError,
  deliveredorderData,
  readyOrderAction,
}) => {
  const [presentAlert] = useIonAlert();
  const [messageToast] = useIonToast();
  const [orderDeliveredListData, setorderDeliveredListData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenu, setselectedMenu] = useState([
    {
      menuDetails: { image_link: "", price: 0, menuName: "" },
      quantity: 0,
      subMenuDetails: { image_link: "", price: 0, subCategoryName: "" },
    },
  ]);
  const [productTotal, setproductTotal] = useState(0);
  const [data, setData] = useState({
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
  });
  const [orderDetails, setOrderDetails] = useState({
    orderId: "",
    discount: 0,
  });
  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };
  useIonViewWillEnter(() => {
    setMenuAction(true);
    getDeliveredOrderAction(data);
  });

  useEffect(() => {
    if (isDeliveredOrderSuccess) {
      setorderDeliveredListData(deliveredorderData.responses);
    }
  }, [isDeliveredOrderSuccess]);

  const getProductTotal = (productTotalCal: any, discount: number) => {
    if (discount > 0) {
      let afterDiscount = productTotalCal - (productTotalCal * discount) / 100;
      setproductTotal(afterDiscount);
    } else {
      setproductTotal(productTotalCal);
    }
  };
  const onChange = (valueEvent: any, nameEvent: any, val = null) => {
    let value = val || valueEvent;
    let name = nameEvent;
    if (name == "toDate") {
      value = moment(value).add(1, "days").format("YYYY-MM-DD");
    }
    setData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleDateChange = (e: any) => {
    console.log(e);
    console.log(e.target.value, e.target.name);
    onChange(e.target.value, e.target.name);
  };

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop && */}
        <>
          <div className="listing-block">
            <IonLabel className="pagename">
              <h2>Orders Delivered</h2>
            </IonLabel>

            <IonCard className="table-layout">
              <IonCardContent>
                {/* <IonRow>
                  <IonCol size="6" size-md="6">
                    Filter:
                    <select className="input-field">
                      <option>select</option>
                      <option>Breakfast</option>
                      <option>Lunch</option>
                      <option>Snacks</option>
                    </select>
                  </IonCol>

                  <IonCol size="6" size-md="6">
                    <input
                      type="text"
                      placeholder="Search by name, id, context"
                      className="input-field input-field-full float-right"
                    />
                  </IonCol>
                </IonRow> */}
                <IonRow>
                  <IonCol size="4" size-md="4" style={{ marginTop: 16 }}>
                    <label style={{ margin: ".4rem 0" }} htmlFor="start">
                      Start date :{" "}
                    </label>
                    <input
                      type="date"
                      id="from"
                      name="fromDate"
                      onChange={(e) => handleDateChange(e)}
                      // min="2018-01-01" max="2018-12-31"
                    />
                  </IonCol>
                  <IonCol size="4" size-md="4" style={{ marginTop: 16 }}>
                    <label htmlFor="start" style={{ margin: ".4rem 0" }}>
                      End date :{" "}
                    </label>
                    <input
                      type="date"
                      id="to"
                      name="toDate"
                      onChange={(e) => handleDateChange(e)}
                    />
                  </IonCol>
                  <IonCol size="4" size-md="4">
                    <IonButton onClick={() => getDeliveredOrderAction(data)}>
                      Get Data
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="table-wrapper">
                      <table id="order-delivered">
                        <tbody>
                          <tr>
                            <th>Order ID</th>
                            <th>Menu ID</th>
                            <th>User ID</th>
                            <th>Ready Time</th>
                            <th>Payment Mode</th>
                            <th>Subsidy Order</th>
                          </tr>

                          {orderDeliveredListData.length > 0 &&
                            orderDeliveredListData.map(
                              (order: any, index: Number) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{order.orderId}</td>
                                      <td>
                                        <a
                                          onClick={() => {
                                            setIsOpen(true);
                                            setselectedMenu(
                                              order.orderMenuResponse
                                            );
                                            setOrderDetails(order);
                                            let productTotalCal = 0;
                                            order.orderMenuResponse?.forEach(
                                              (elm: any) => {
                                                productTotalCal +=
                                                  (elm.menuDetails
                                                    ? elm.menuDetails.price
                                                    : elm.subMenuDetails
                                                        .price) * elm.quantity;
                                              }
                                            );

                                            getProductTotal(
                                              productTotalCal,
                                              order.discount
                                            );
                                          }}
                                        >
                                          View
                                        </a>
                                      </td>

                                      <td>{order.createdBy}</td>
                                      <td>
                                        {moment(order.createdOn).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {order.paymentMode == 1
                                          ? "PAID AT COUNTER"
                                          : order.paymentMode == 2
                                          ? "PAID ONLINE"
                                          : order.paymentMode == 3
                                          ? "PAID VIA SODEXO"
                                          : "-"}
                                      </td>
                                      <td>
                                        {order.discount > 0 ? "YES" : "NO"}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </div>{" "}
          <IonModal
            isOpen={isOpen}
            backdropDismiss={false}
            className="large-modal-custom print-bill-popup"
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <IonList>
                {selectedMenu.length > 0 && (
                  <div className="ion-padding">
                    <table className="menu-title-print">
                      <tr>
                        <th>VENTURE PROFESSIONALS</th>
                      </tr>
                      <tr>
                        <td> {moment().format("DD/MM/YYYY hh:mm:ss")}</td>
                      </tr>
                      <tr>
                        <td>VP - {orderDetails.orderId}</td>
                      </tr>
                    </table>

                    <table className="menu-item-print">
                      <tr>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Quantity</th>
                      </tr>

                      {selectedMenu.map((menu, index) => {
                        return (
                          <tr>
                            <td>
                              {" "}
                              {menu.menuDetails
                                ? menu.menuDetails.menuName
                                : menu.subMenuDetails.subCategoryName}
                            </td>
                            <td>
                              INR.{" "}
                              {menu.menuDetails
                                ? menu.menuDetails.price
                                : menu.subMenuDetails.price}
                            </td>
                            <td>{menu.quantity} unit</td>
                          </tr>
                        );
                      })}
                    </table>

                    <table className="menu-total-print">
                      <tr>
                        <th>Total</th>
                        <th></th>
                        <th>
                          INR. {productTotal}{" "}
                          {orderDetails.discount > 0 && "Subsidy Applied"}
                        </th>
                      </tr>

                      {/* <tr>
                      <td>Discount</td>
                      <td></td>
                      <td>INR. </td>
                    </tr>

                    <tr>
                      <td>Net payable</td>
                      <td></td>
                      <td>INR. </td>
                    </tr> */}
                    </table>

                    <table className="menu-title-print">
                      <tr>
                        <td>Thank you for ordering food.</td>
                      </tr>
                    </table>

                    <table className="menu-btn-print">
                      <tr>
                        <td>
                          <button onClick={() => window.print()}> Print</button>
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
              </IonList>
            </IonContent>
          </IonModal>
        </>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isDeliveredOrderSuccess: state.counterheadData.isDeliveredOrderSuccess,
  isDeliveredOrderError: state.counterheadData.isDeliveredOrderError,
  deliveredorderData: state.counterheadData.deliveredorderData,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getDeliveredOrderAction: (data: Number) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_DELIVERED_ORDER_REQUEST,
        data: data,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderRequestDelivered));
