import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
} from "@ionic/react";
import { ACTION_TYPES } from "../../../redux/user/orders/actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import { trashOutline, checkmarkCircleOutline } from "ionicons/icons";

interface OwnProps {}

interface DispatchProps {}

interface MenuProps {
  menuName: "";
  subMenuDetails: [
    { subCategoryName: ""; isIncludedInPackage: false; smdId: 0 }
  ];
  availableQty: "";
  price: 0;
  mdId: 0;
  image_link: "";
  menuId: "";
  status: 1;
  isIncludedInPackage: null;
  quantity: 0;
}
interface MenusProps extends OwnProps, DispatchProps {
  getMenuListAction: Function;
  isMenuSuccess: boolean;
  isMenuError: boolean;
  updateMenuAction: Function;
  isMenuUpdatedSuccess: boolean;
  isMenuUpdatedError: boolean;
  menuData: Array<MenuProps>;
}
const Menus: React.FC<MenusProps> = ({
  getMenuListAction,
  isMenuSuccess,
  isMenuError,
  updateMenuAction,
  isMenuUpdatedSuccess,
  isMenuUpdatedError,
  menuData,
}) => {
  useEffect(() => {
    getMenuListAction();
  }, []);

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop && */}
        <>
          <div className="listing-block">
            <IonLabel className="pagename">
              <h2>Master Menu</h2>
            </IonLabel>

            <IonCard className="table-layout">
              <IonCardContent>
                {menuData.length > 0 && (
                  <IonRow>
                    <IonCol size="12" size-md="12">
                      <div className="table-wrapper">
                        <table>
                          <tr>
                            <th>Menu ID</th>
                            <th>Menu Image</th>
                            <th>Menu Name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Status</th>
                            <th>Included in Package</th>
                            <th>Edit</th>
                          </tr>
                          {menuData.map((menu, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{menu.mdId}</td>
                                  <td>
                                    {" "}
                                    <img
                                      alt=""
                                      width={50}
                                      height={50}
                                      src={
                                        menu.image_link
                                          ? menu.image_link
                                          : "assets/img/menu/default.png"
                                      }
                                    />
                                  </td>
                                  <td>{menu.menuName}</td>
                                  <td>{menu.price}</td>
                                  <td>{menu.availableQty}</td>
                                  <th>
                                    {menu.status == 1 ? "Active" : "Inactive"}
                                  </th>
                                  <th>
                                    {menu.isIncludedInPackage == 1
                                      ? "Yes"
                                      : "No"}
                                  </th>
                                  <td>
                                    <IonButton
                                    // onClick={() => handleMenuEdit(menu)}
                                    >
                                      {/* <IonIcon src={pencilOutline} /> */}
                                    </IonButton>{" "}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </table>
                      </div>
                    </IonCol>
                  </IonRow>
                )}

                {menuData.length == 0 && <div>Menu data Is Empty...</div>}
              </IonCardContent>
            </IonCard>
          </div>
        </>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  menuData: state.orders.menuData,
  isMenuSuccess: state.orders.isMenuSuccess,
  isMenuError: state.orders.isMenuUpdatedSuccess,
  isMenuUpdatedSuccess: state.orders.isMenuUpdatedSuccess,
  isMenuUpdatedError: state.orders.isMenuUpdatedError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    getMenuListAction: () =>
      dispatch({
        type: ACTION_TYPES.GET_MENU_REQUEST,
      }),
    updateMenuAction: (subMenu: boolean) =>
      dispatch({
        type: ACTION_TYPES.UPDATE_MENU_REQUEST,
        payload: subMenu,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menus);
