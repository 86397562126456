import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  useIonToast,
} from "@ionic/react";
import "./Login.scss";
import { RouteComponentProps } from "react-router";
import axios from "axios";
import CopyRight from "../components/CopyRight";
import { connect } from "react-redux";
import { ACTION_TYPES } from "../redux/auth/actions";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface ForgotPasswordProps extends OwnProps, DispatchProps {
  isForgotPasswordSuccess: boolean;
  isForgotPasswordError: boolean;
  forgotPasswordAction: Function;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  history,
  isForgotPasswordSuccess,
  isForgotPasswordError,
  forgotPasswordAction,
}) => {
  const [data, setData] = useState({ mobileNo: "" });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [mobileError, setOtpError] = useState(false);
  const [messageToast] = useIonToast();

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };
  useEffect(() => {
    if (isForgotPasswordSuccess) {
      localStorage.setItem("isRegister", "false");
      history.push("/verifyOTP", { direction: "none" });
    }
  }, [isForgotPasswordSuccess]);

  useEffect(() => {
    if (isForgotPasswordError) {
      presentToast("top", "Some issue occurred ,Please try again later.");
    }
  }, [isForgotPasswordError]);

  const submit = async (e: React.FormEvent) => {
    setFormSubmitted(true);
    if (!data["mobileNo"]) {
      setOtpError(true);
    } else {
      forgotPasswordAction(data);
    }
  };

  const onChange = (valueEvent: any, nameEvent: any, val = null) => {
    const value = val || valueEvent;
    const name = nameEvent;

    setData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleChange = (e: any) => {
    onChange(e.target.value, e.target.name);
  };

  return (
    <IonPage id="login-page">
      <IonContent className="sticky-container">
        <div className="login-logo">
          <img src="assets/img/logo.png" alt="Ionic logo" />
        </div>

        <form className="small-form" noValidate>
          <h3>Forgot password</h3>

          <IonList>
            <IonItem>
              <IonLabel position="floating">Mobile No.</IonLabel>
              <IonInput
                name="mobileNo"
                type="text"
                value={data["mobileNo"]}
                onIonChange={(e) => {
                  handleChange(e);
                }}
              ></IonInput>
            </IonItem>
            {formSubmitted && mobileError && (
              <IonText color="danger">
                <p className="ion-padding-start">Mobile no. is required</p>
              </IonText>
            )}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="button" expand="block" onClick={submit}>
                Submit
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className="adjust-row">
            <IonText color="primary">
              <a href="/signin">Go Back</a>
            </IonText>
          </IonRow>
        </form>
        <CopyRight></CopyRight>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isForgotPasswordSuccess: state.auth.isForgotPasswordSuccess,
  isForgotPasswordError: state.auth.isForgotPasswordError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    forgotPasswordAction: (data: {}) =>
      dispatch({
        type: ACTION_TYPES.FORGOT_PASSWORD_REQUEST,
        payload: data,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
