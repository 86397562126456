import React, { useState, useEffect } from "react";
import {
  useIonToast,
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
} from "@ionic/react";
import "./Login.scss";
import { RouteComponentProps } from "react-router";
import Footer from "../components/Footer";
import { useFormik } from "formik";
import { fetchRegisterRequest, ACTION_TYPES } from "../redux/auth/actions";
import { DATA_ACTION_TYPES } from "../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import CopyRight from "../components/CopyRight";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface SignupProps extends OwnProps, DispatchProps {
  registerAction: Function;
  isRegisterSuccess: boolean;
  isRegisterError: boolean;
  setMenuAction: Function;
  userData: any;
}
const Signup: React.FC<SignupProps> = ({
  history,
  registerAction,
  isRegisterSuccess,
  isRegisterError,
  setMenuAction,
  userData,
}) => {
  useIonViewWillEnter(() => {
    setMenuAction(false);
  });
  const [messageToast] = useIonToast();
  const [data, setData] = useState({
    lastname: "",
    firstname: "",
    password: "",
    mobile: "",
    empId: 0,
    empSubsidy: 0,
  });
  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.firstname) {
      errors.firstname = "Required";
    } else if (values.firstname.length > 32) {
      errors.firstname = "Must be 32 characters or less";
    }

    if (!values.lastname) {
      errors.lastname = "Required";
    } else if (values.lastname.length > 32) {
      errors.lastname = "Must be 32 characters or less";
    }

    if (!values.mobile) {
      errors.mobile = "Required";
    } else if (values.mobile.toString().length > 10) {
      errors.mobile = "Must be 10 digit mobile number";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length > 60) {
      errors.password = "Must be 60 characters or less";
    }

    if (!values.empId || values.empId == 0) {
      errors.empId = "Required";
    } else if (values.empId.toString().length > 7) {
      errors.empId = "Must be max 7 characters";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      mobile: "",
      password: "",
      empId: 0,
      empSubsidy: 0,
    },
    validate,
    onSubmit: (values) => {
      registerAction(values);
    },
  });

  useEffect(() => {
    if (isRegisterSuccess) {
      presentToast("top", "User signup successfully.");
      history.push("/verifyOTP", { direction: "none" });
    }
  }, [isRegisterSuccess]);

  useEffect(() => {
    if (isRegisterError) {
      presentToast(
        "top",
        userData.message
          ? userData.message
          : "Your request cannot be processed,please try later."
      );
    }
  }, [isRegisterError]);

  return (
    <IonPage id="signup-page">
      <IonContent className="sticky-container">
        <div className="login-logo">
          <img src="assets/img/logo.png" alt="Ionic logo" />
        </div>

        <form className="small-form" onSubmit={formik.handleSubmit}>
          <h3>Sign Up</h3>

          <IonList>
            <IonItem>
              <IonLabel position="floating">First name</IonLabel>
              <IonInput
                id="firstname"
                name="firstname"
                type="text"
                onIonChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstname}
                spellCheck={false}
                autocapitalize="off"
                required
                maxlength={32}
              ></IonInput>
            </IonItem>

            {formik.touched.firstname && formik.errors.firstname ? (
              <IonText color="danger">{formik.errors.firstname}</IonText>
            ) : null}

            <IonItem>
              <IonLabel position="floating">Last name</IonLabel>
              <IonInput
                id="lastname"
                name="lastname"
                type="text"
                onIonChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastname}
                spellCheck={false}
                autocapitalize="off"
                required
                maxlength={32}
              ></IonInput>
            </IonItem>

            {formik.touched.lastname && formik.errors.lastname ? (
              <IonText color="danger">{formik.errors.lastname}</IonText>
            ) : null}

            <IonItem>
              <IonLabel position="floating">Mobile number</IonLabel>
              <IonInput
                id="mobile"
                name="mobile"
                type="number"
                onIonChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
                spellCheck={false}
                autocapitalize="off"
                required
                maxlength={10}
              ></IonInput>
            </IonItem>

            {formik.touched.mobile && formik.errors.mobile ? (
              <IonText color="danger">{formik.errors.mobile}</IonText>
            ) : null}

            <IonItem>
              <IonLabel position="floating">Password</IonLabel>
              <IonInput
                id="password"
                name="password"
                type="password"
                onIonChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                spellCheck={false}
                autocapitalize="off"
                required
                maxlength={60}
              ></IonInput>
            </IonItem>

            {formik.touched.password && formik.errors.password ? (
              <IonText color="danger">{formik.errors.password}</IonText>
            ) : null}

            <IonItem>
              <IonLabel position="floating">Employee code</IonLabel>
              <IonInput
                id="empId"
                name="empId"
                type="number"
                onIonChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.empId}
                spellCheck={false}
                autocapitalize="off"
                required
                maxlength={7}
              ></IonInput>
            </IonItem>

            {formik.touched.empId && formik.errors.empId ? (
              <IonText color="danger">{formik.errors.empId}</IonText>
            ) : null}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">
                Sign up
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow className="adjust-row">
            <IonText>By signin up, I accept the</IonText>
          </IonRow>
          <IonRow className="adjust-row">
            <IonText color="primary">
              <a href="/terms">Terms of service</a>
            </IonText>
            <span className="adjust-margin">and</span>
            <IonText color="primary">
              <a href="/privacy-policy">Privacy policy</a>
            </IonText>
          </IonRow>

          <hr />

          <IonRow className="adjust-row">
            <IonText>Already have an account?</IonText>
            <span className="adjust-margin">&#9679;</span>
            <IonText color="primary">
              <a href="/signin">Sign in</a>
            </IonText>
          </IonRow>
        </form>
        <CopyRight></CopyRight>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.auth.userData,
  isRegisterSuccess: state.auth.isRegisterSuccess,
  isRegisterError: state.auth.isRegisterError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    registerAction: (userData: {}) =>
      dispatch({
        type: ACTION_TYPES.REGISTER_REQUEST,
        payload: userData,
      }),
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
