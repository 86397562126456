import React, { useState, useEffect } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  useIonToast,
} from "@ionic/react";
import "./Login.scss";
import { RouteComponentProps } from "react-router";
import axios from "axios";
import Footer from "../components/Footer";
import { useFormik } from "formik";
import { ACTION_TYPES } from "../redux/auth/actions";
import { DATA_ACTION_TYPES } from "../redux/sessions/action";
import { connect } from "react-redux";
import CopyRight from "../components/CopyRight";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}
interface LoginProps extends OwnProps, DispatchProps {
  loginData: any;
  loginAction: Function;
  isLoginSuccess: boolean;
  isLoginError: boolean;
  setMenuAction: Function;
}
const Signin: React.FC<LoginProps> = ({
  history,
  loginAction,
  isLoginSuccess,
  isLoginError,
  setMenuAction,
  loginData,
}) => {
  const [messageToast] = useIonToast();

  const [data, setData] = useState({ password: "", mobileNo: "" });
  const [contactError, setContactError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  useIonViewWillEnter(() => {
    localStorage.clear();
    setMenuAction(false);
  });

  useEffect(() => {
    if (isLoginSuccess) {
      presentToast("top", "User logged in successfully.");

      if (localStorage.getItem("userDetails") != null) {
        let userDetails = JSON.parse(localStorage.getItem("userDetails")!);
        if (userDetails.role == "CounterHead") {
          history.push("/counter/dashboard", { direction: "none" });
        } else if (userDetails.role == "User") {
          history.push("/menu", { direction: "none" });
        } else if (userDetails.role == "Admin") {
          history.push("/admin/dashboard", { direction: "none" });
        }
      }

      window.location.reload();
    }
  }, [isLoginSuccess]);

  useEffect(() => {
    if (isLoginError) {
      presentToast(
        "top",
        loginData.message
          ? loginData.message
          : "Your request cannot be processed,please try later."
      );
      if (loginData.status == 102) {
        localStorage.setItem("isRegister", "true");
        localStorage.setItem(
          "registeredMobile",
          JSON.stringify(formik.values.mobileNo)
        );
        history.push("/verifyOTP", { direction: "none" });
      }
    }
  }, [isLoginError]);

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.mobileNo) {
      errors.mobileNo = "Required";
    } else if (values.mobileNo.toString().length > 10) {
      errors.mobileNo = "Must be 10 digit mobileNo number";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length > 60) {
      errors.password = "Must be 60 characters or less";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      mobileNo: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      loginAction(values);
    },
  });

  return (
    <IonPage id="login-page">
      <IonContent className="sticky-container">
        <div className="login-logo">
          <img src="assets/img/logo.png" alt="Ionic logo" />
        </div>

        <form className="small-form" noValidate onSubmit={formik.handleSubmit}>
          <h3>Sign In</h3>

          <IonList>
            <IonItem>
              <IonLabel position="floating">Mobile number</IonLabel>
              <IonInput
                id="mobileNo"
                name="mobileNo"
                type="number"
                onIonChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobileNo}
                spellCheck={false}
                autocapitalize="off"
                required
                maxlength={10}
              ></IonInput>
            </IonItem>

            {formik.touched.mobileNo && formik.errors.mobileNo ? (
              <IonText color="danger">{formik.errors.mobileNo}</IonText>
            ) : null}

            <IonItem>
              <IonLabel position="floating">Password</IonLabel>
              <IonInput
                id="password"
                name="password"
                type="password"
                onIonChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                spellCheck={false}
                autocapitalize="off"
                required
                maxlength={60}
              ></IonInput>
            </IonItem>

            {formik.touched.password && formik.errors.password ? (
              <IonText color="danger">{formik.errors.password}</IonText>
            ) : null}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">
                Sign In
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow className="adjust-row">
            <IonText>Can't sign in?</IonText>
            <span className="adjust-margin">&#9679;</span>
            <IonText color="primary">
              <a href="/forgotPassword">Forgot password</a>
            </IonText>
          </IonRow>

          <hr />

          <IonRow className="adjust-row">
            <IonText>Don't have account?</IonText>
            <span className="adjust-margin">&#9679;</span>
            <IonText color="primary">
              <a href="/signup">Sign up</a>
            </IonText>
          </IonRow>
        </form>

        <CopyRight></CopyRight>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  loginData: state.auth.loginData,
  isLoginSuccess: state.auth.isLoginSuccess,
  isLoginError: state.auth.isLoginError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: (userData: {}) =>
      dispatch({
        type: ACTION_TYPES.LOGIN_REQUEST,
        payload: userData,
      }),
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
