import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonBadge,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import {
  trashOutline,
  checkmarkCircleOutline,
  calendar,
  informationCircle,
  map,
  personCircle,
} from "ionicons/icons";
import Rater from "react-rater";
import { COUNTERHEAD_ACTION_TYPES } from "../../redux/user/counterhead/actions";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface OccupancyProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  isgetSeatingCountSuccess: boolean;
  isgetSeatingCountError: boolean;
  seatingCountData: any;
  getSeatingCountAction: Function;
  updateTiffinUserCountAction: Function;

  isTiffinUserCountSuccess: boolean;
  isTiffinUserCountError: boolean;
}
const Occupancy: React.FC<OccupancyProps> = ({
  setMenuAction,
  isgetSeatingCountSuccess,
  isgetSeatingCountError,
  seatingCountData,
  getSeatingCountAction,
  updateTiffinUserCountAction,
  isTiffinUserCountSuccess,
  isTiffinUserCountError,
  history,
}) => {
  const [presentAlert] = useIonAlert();
  const [messageToast] = useIonToast();
  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };
  const [occupancyData, setoccupancyData] = useState({
    totalSeats: 0,
    tiffinUsers: 0,
    activeOrders: 0,
  });

  useEffect(() => {
    setMenuAction(true);
    getSeatingCountAction();
  }, []);

  const reset = () => {
    history.push("/counter/dashboard", { direction: "none" });
  };

  const updateOccupancy = () => {
    updateTiffinUserCountAction({ tiffinUsers: occupancyData.tiffinUsers });
  };

  useEffect(() => {
    if (isgetSeatingCountSuccess) {
      setoccupancyData(seatingCountData["seatingCount"]);
    }
  }, [isgetSeatingCountSuccess]);

  useEffect(() => {
    if (isTiffinUserCountSuccess) {
      presentToast("top", "Occupancy data updated successfully.");
    }
  }, [isTiffinUserCountSuccess]);

  useEffect(() => {
    if (isTiffinUserCountError) {
      presentToast("top", "Cannot update. Please try again later.");
    }
  }, [isTiffinUserCountError]);

  const onChange = (valueEvent: any, nameEvent: any, val = null) => {
    const value = val || valueEvent;
    const name = nameEvent;

    setoccupancyData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleChange = (e: any) => {
    onChange(e.target.value, e.target.name);
  };

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop &&
                    <> */}

        <div className="listing-block">
          <IonLabel className="pagename">
            <h2>Seat Occupancy</h2>
          </IonLabel>

          <IonCard className="table-layout">
            <IonCardContent>
              <IonRow>
                <IonCol size="9" size-md="9">
                  <IonItem fill="outline">
                    <IonLabel position="floating">
                      Total seats capacity
                    </IonLabel>
                    <IonInput
                      id="totalSeats"
                      name="totalSeats"
                      type="text"
                      // onIonChange={(e) => {
                      //   setoccupancyData((prevState: any) => ({
                      //     ...prevState,
                      //     total: e.target.value,
                      //   }));
                      // }}
                      // onBlur={formik.handleBlur}
                      value={occupancyData["totalSeats"]}
                      required
                      min={1}
                      readonly
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="9" size-md="9">
                  <IonItem fill="outline">
                    <IonLabel position="floating">Buffer seats</IonLabel>
                    <IonInput
                      id="tiffinUsers"
                      name="tiffinUsers"
                      type="number"
                      onIonChange={(e) => {
                        handleChange(e);
                      }}
                      value={occupancyData["tiffinUsers"]}
                      // onBlur={formik.handleBlur}
                      required
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="9" size-md="9">
                  <IonItem fill="outline">
                    <IonLabel position="floating">Filled seats</IonLabel>
                    <IonInput
                      id="activeOrders"
                      name="activeOrders"
                      type="number"
                      // onIonChange={(e) => {
                      //   setoccupancyData((prevState: any) => ({
                      //     ...prevState,
                      //     activeOrders: e.target.value,
                      //   }));
                      // }}
                      // onBlur={formik.handleBlur}
                      // value={selectedMenu.menuName}
                      value={occupancyData["activeOrders"]}
                      required
                      min={1}
                      readonly
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonButton type="button" onClick={() => updateOccupancy()}>
                    Update
                  </IonButton>
                  <IonButton type="button" onClick={() => reset()}>
                    Cancel
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isgetSeatingCountSuccess: state.counterheadData.isgetSeatingCountSuccess,
  isgetSeatingCountError: state.counterheadData.isgetSeatingCountError,
  seatingCountData: state.counterheadData.seatingCountData,

  isTiffinUserCountSuccess: state.counterheadData.isTiffinUserCountSuccess,
  isTiffinUserCountError: state.counterheadData.isTiffinUserCountError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getSeatingCountAction: () =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_SEATING_COUNT_REQUEST,
      }),
    updateTiffinUserCountAction: (data: any) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.UPDATE_TIFFIN_USER_COUNT_REQUEST,
        payload: data,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Occupancy));
