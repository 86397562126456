import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  useIonViewWillEnter,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonDatetime,
  IonSelectOption,
  IonList,
  IonItem,
  IonLabel,
  IonSelect,
  IonPopover,
  IonText,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
  IonInput,
  useIonToast,
} from "@ionic/react";
import {
  checkmarkCircleOutline,
  ellipsisHorizontal,
  ellipsisVertical,
  timeOutline,
  trashOutline,
  addOutline,
  arrowBack,
} from "ionicons/icons";
import AuthHeader from "../../components/AuthHeader";
import { ACTION_TYPES, setCartCount } from "../../redux/user/orders/actions";
import { connect } from "react-redux";
import { isDesktop, isMobile } from "react-device-detect";
import { RouteComponentProps, withRouter } from "react-router";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import displayRazorpay from "../../utils/PaymentGateway";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}
interface CartProps extends OwnProps, DispatchProps {
  resetAction: Function;
  placeOrderAction: Function;
  orderedData: any;
  isSetOrderSuccess: boolean;
  isSetOrderError: boolean;
  getCartAction: Function;
  isCartGetError: boolean;
  isCartGetSuccess: boolean;
  cartItemsData: any;
  deleteItemAction: Function;
  updateCartAction: Function;
  isCartUpdatedSuccess: boolean;
  isCartUpdatedError: boolean;
  isCartDeletedSuccess: boolean;
  isCartDeletedError: boolean;
  setMenuAction: Function;
  setCartCountAction: Function;
  resetPayAction: Function;
}
const Cart: React.FC<CartProps> = ({
  resetAction,
  setMenuAction,
  history,
  placeOrderAction,
  isSetOrderSuccess,
  isSetOrderError,
  orderedData,
  getCartAction,
  isCartGetError,
  isCartGetSuccess,
  cartItemsData,
  deleteItemAction,
  isCartDeletedSuccess,
  isCartDeletedError,
  updateCartAction,
  isCartUpdatedSuccess,
  isCartUpdatedError,
  setCartCountAction,
  resetPayAction,
}) => {
  const [messageToast] = useIonToast();
  const [cartItems, setcartItems] = useState([
    { menuName: "", image_link: "", quantity: 1, price: 0, menuId: 0 },
  ]);
  const [cartLength, setcartLength] = useState([]);
  const [productTotal, setproductTotal] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [total, settotal] = useState(0);

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };

  useIonViewWillEnter(() => {
    getCartAction();
    setMenuAction(true);
    resetPayAction();
  }, []);

  useEffect(() => {
    if (isCartUpdatedSuccess) {
      presentToast("top", "Cart updated successfully.");
      settotal(0);
      setdiscount(0);
      setproductTotal(0);
      getCartAction();
    }
  }, [isCartUpdatedSuccess]);

  useEffect(() => {
    if (isCartDeletedSuccess) {
      presentToast("top", "Cart deleted successfully.");
      settotal(0);
      setdiscount(0);
      setproductTotal(0);
      getCartAction();
    }
  }, [isCartDeletedSuccess]);

  useEffect(() => {
    if (isCartGetSuccess) {
      if (cartItemsData?.cartList.length > 0) {
        setcartItems(cartItemsData?.cartList);
        let productTotalCal = 0;
        cartItemsData?.cartList.forEach((elm: any) => {
          productTotalCal += elm.price * elm.quantity;
        });

        setcartLength(cartItemsData?.cartList);
        setproductTotal(productTotalCal);
        settotal(0);
        if (productTotalCal > 30) {
          setdiscount(cartItemsData?.discount);
          let afterDiscount =
            productTotalCal - (productTotalCal * cartItemsData?.discount) / 100;

          settotal(afterDiscount);
        } else {
          setdiscount(0);
          settotal(productTotalCal);
        }
      } else if (cartItemsData?.cartList.length == 0) {
        setcartItems([
          { menuName: "", image_link: "", quantity: 1, price: 0, menuId: 0 },
        ]);
        setcartLength([]);
        localStorage.setItem("cartItemCount", JSON.stringify(0));
        localStorage.removeItem("cartItems");
        setCartCountAction(0);

        // window.location.reload();
      }
    }
  }, [isCartGetSuccess]);

  const handleChange = (action: any, selectedCart: any) => {
    let updatedQty = 0;
    if (action == "add") {
      updatedQty = selectedCart.quantity + 1;
    } else {
      updatedQty = selectedCart.quantity - 1;
    }

    if (updatedQty <= 0) {
      return;
    }

    let cartItemData = {
      counterId: selectedCart.counterId,
      status: selectedCart.status,
      menuId: selectedCart.menuId,
      quantity: updatedQty,
      cartItemNumber: selectedCart.cartItemNumber,
    };

    updateCartAction(cartItemData);
  };

  const deleteItem = (selectedCart: any) => {
    deleteItemAction({ cartItemNumber: selectedCart["cartItemNumber"] });
  };

  const placeOrder = () => {
    let orderMenus: { menuId: number; quantity: number }[] = [];

    if (total > 0) {
      cartItems.forEach((element) => {
        orderMenus.push({
          menuId: element.menuId,
          quantity: element.quantity,
        });
      });

      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      history.push("/payments", { direction: "none" });
    } else {
      presentToast("top", "Cannot proceed. Total amount is invalid.");
    }
  };

  // const handlePayments = async () => {

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {isDesktop && (
          <>
            {cartLength.length > 0 && (
              <div className="listing-block ion-padding">
                <IonLabel className="pagename">
                  <h2>Cart</h2>
                </IonLabel>

                <IonCard className="display-card cart-view">
                  <IonCardContent>
                    {cartLength.length > 0 && (
                      <IonItem lines="none">
                        <IonGrid>
                          <IonRow className="cart-header">
                            <IonCol
                              size="1"
                              size-md="1"
                              className="product-image-block"
                            ></IonCol>
                            <IonCol
                              size="3"
                              size-md="3"
                              className="product-detail-block"
                            ></IonCol>
                            <IonCol
                              size="3"
                              size-md="3"
                              className="product-detail-block"
                            >
                              Price
                            </IonCol>
                            <IonCol
                              size="3"
                              size-md="3"
                              className="product-detail-block"
                            >
                              Quantity
                            </IonCol>
                            <IonCol
                              size="2"
                              size-md="2"
                              className="product-detail-block"
                            ></IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonItem>
                    )}
                    {cartLength.length > 0 &&
                      cartItems.map((cart, index) => {
                        return (
                          <>
                            <IonItem lines="none">
                              <IonGrid>
                                <IonRow>
                                  <IonCol
                                    size="1"
                                    size-md="1"
                                    className="product-image-block"
                                  >
                                    <img alt="" src={cart.image_link} />
                                  </IonCol>
                                  <IonCol
                                    size="3"
                                    size-md="3"
                                    className="product-detail-block"
                                  >
                                    {cart.menuName}
                                  </IonCol>
                                  <IonCol
                                    size="3"
                                    size-md="3"
                                    className="product-detail-block"
                                  >
                                    INR. {cart.price}
                                  </IonCol>
                                  <IonCol
                                    size="3"
                                    size-md="3"
                                    className="product-detail-block"
                                  >
                                    <div className="input-count">
                                      <IonItem>
                                        {/* <IonSelect interface="popover" value={cart.quantity} onIonChange={(evt) => handleChange(evt, cart)}>
                                                                        <IonSelectOption value={1}>1</IonSelectOption>
                                                                        <IonSelectOption value={2}>2</IonSelectOption>
                                                                        <IonSelectOption value={3}>3</IonSelectOption>
                                                                        <IonSelectOption value={4}>4</IonSelectOption>
                                                                        <IonSelectOption value={5}>5</IonSelectOption>
                                                                    </IonSelect> */}
                                        <IonButton
                                          onClick={(evt) =>
                                            handleChange("reduce", cart)
                                          }
                                        >
                                          -
                                        </IonButton>
                                        <IonInput
                                          name="quantity"
                                          type="number"
                                          min={0}
                                          max={5}
                                          value={cart.quantity}
                                        ></IonInput>
                                        <IonButton
                                          onClick={(evt) =>
                                            handleChange("add", cart)
                                          }
                                        >
                                          +
                                        </IonButton>
                                      </IonItem>
                                    </div>
                                  </IonCol>
                                  <IonCol
                                    size="2"
                                    size-md="2"
                                    className="product-detail-block"
                                  >
                                    <IonButton onClick={() => deleteItem(cart)}>
                                      <IonIcon src={trashOutline} />
                                    </IonButton>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonItem>
                          </>
                        );
                      })}

                    {cartLength.length > 0 && (
                      <div className="order-summary">
                        <table>
                          <tr>
                            <td>
                              <strong>Order Summary</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Product Total:</strong>
                            </td>
                            <td>{productTotal}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Discount:</strong>
                            </td>
                            <td>{discount} %</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Total:</strong>
                            </td>
                            <td>INR. {total}</td>
                          </tr>
                        </table>
                      </div>
                    )}
                  </IonCardContent>
                </IonCard>
                {cartLength.length > 0 && (
                  <IonButton onClick={() => placeOrder()}>
                    <IonIcon src={checkmarkCircleOutline} /> Proceed
                  </IonButton>
                )}
              </div>
            )}

            {cartLength.length == 0 && (
              <div className="listing-block ion-padding">
                <IonLabel className="pagename">
                  <h2>Cart</h2>
                </IonLabel>
                <IonCard className="display-card action-cover">
                  <IonCardContent>
                    <picture>
                      <img
                        src="assets/img/art/food-counter.jpg"
                        alt="Food Cart"
                      />
                    </picture>
                    <p>Sorry your cart is empty, please add something</p>
                  </IonCardContent>
                </IonCard>
              </div>
            )}
          </>
        )}

        {isMobile && (
          <>
            <div className="listing-block">
              {" "}
              {cartLength.length > 0 && (
                <>
                  <IonLabel className="pagename">
                    <h2>Cart</h2>
                  </IonLabel>

                  <IonCard className="display-card cart-view mob-card-view">
                    <IonCardContent>
                      {cartItems.map((cart, index) => {
                        return (
                          <>
                            <IonItem lines="none">
                              <IonGrid>
                                <IonRow className="cart-header">
                                  <IonCol
                                    size="2"
                                    size-md="2"
                                    className="product-image-block"
                                  ></IonCol>
                                  <IonCol
                                    size="3"
                                    size-md="3"
                                    className="product-detail-block"
                                  >
                                    Details
                                  </IonCol>
                                  <IonCol
                                    size="5"
                                    size-md="5"
                                    className="product-detail-block"
                                  >
                                    Qty
                                  </IonCol>
                                  <IonCol
                                    size="2"
                                    size-md="2"
                                    className="product-detail-block"
                                  ></IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonItem>
                            <IonItem lines="none">
                              <IonGrid>
                                <IonRow>
                                  <IonCol
                                    size="3"
                                    size-md="3"
                                    className="product-image-block"
                                  >
                                    <img alt="" src={cart.image_link} />
                                  </IonCol>
                                  <IonCol
                                    size="6"
                                    size-md="6"
                                    className="product-detail-block"
                                  >
                                    {cart.menuName} <br />
                                    INR. {cart.price} <br />
                                    <div>
                                      <IonItem>
                                        <IonButton
                                          onClick={(evt) =>
                                            handleChange("reduce", cart)
                                          }
                                        >
                                          -
                                        </IonButton>
                                        <IonInput
                                          name="quantity"
                                          type="number"
                                          min={0}
                                          max={5}
                                          value={cart.quantity}
                                        ></IonInput>
                                        <IonButton
                                          onClick={(evt) =>
                                            handleChange("add", cart)
                                          }
                                        >
                                          +
                                        </IonButton>
                                      </IonItem>
                                    </div>
                                  </IonCol>
                                  <IonCol
                                    size="3"
                                    size-md="3"
                                    className="product-detail-block"
                                  >
                                    <IonButton onClick={() => deleteItem(cart)}>
                                      <IonIcon src={trashOutline} />
                                    </IonButton>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonItem>
                          </>
                        );
                      })}

                      <div className="order-summary">
                        <table>
                          <tr>
                            <td>
                              <strong>Order Summary</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Product Total:</strong>
                            </td>
                            <td>{productTotal}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Discount:</strong>
                            </td>
                            <td>{discount} %</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Total:</strong>
                            </td>
                            <td>INR. {total}</td>
                          </tr>
                        </table>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </>
              )}
              <div className="mob-card-view-action">
                {cartLength.length > 0 && (
                  <IonButton onClick={() => placeOrder()}>
                    <IonIcon src={checkmarkCircleOutline} /> Proceed
                  </IonButton>
                )}
                {cartLength.length == 0 && (
                  <div className="listing-block ion-padding">
                    <IonLabel className="pagename">
                      <h2>Cart</h2>
                    </IonLabel>
                    <IonCard className="display-card action-cover">
                      <IonCardContent>
                        <picture>
                          <img
                            src="assets/img/art/food-counter.jpg"
                            alt="Food Cart"
                          />
                        </picture>
                        <p>Sorry your cart is empty, please add something</p>
                      </IonCardContent>
                    </IonCard>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  orderedData: state.orders.orderedData,
  isSetOrderSuccess: state.orders.isSetOrderSuccess,
  isSetOrderError: state.orders.isSetOrderError,
  cartItemsData: state.orders.cartItem,
  isCartGetSuccess: state.orders.isCartGetSuccess,
  isCartGetError: state.orders.isCartGetError,
  isCartUpdatedSuccess: state.orders.isCartUpdatedSuccess,
  isCartUpdatedError: state.orders.isCartUpdatedError,
  isCartDeletedSuccess: state.orders.isCartDeletedSuccess,
  isCartDeletedError: state.orders.isCartDeletedError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getCartAction: () =>
      dispatch({
        type: ACTION_TYPES.GET_CART_REQUEST,
      }),
    placeOrderAction: (orderDetails: boolean) =>
      dispatch({
        type: ACTION_TYPES.SET_ORDER_REQUEST,
        payload: orderDetails,
      }),
    deleteItemAction: (deleteCart: any) =>
      dispatch({
        type: ACTION_TYPES.DELETE_CART_REQUEST,
        payload: deleteCart,
      }),
    updateCartAction: (updatedCart: any) =>
      dispatch({
        type: ACTION_TYPES.UPDATE_CART_REQUEST,
        payload: updatedCart,
      }),
    resetAction: () =>
      dispatch({
        type: ACTION_TYPES.RESET_REQUEST,
      }),
    resetPayAction: () =>
      dispatch({
        type: ACTION_TYPES.RESET_PAY_ORDER_ONLINE_REQUEST,
      }),
    setCartCountAction: (count: Number) => dispatch(setCartCount(count)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cart));
