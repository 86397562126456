import { COUNTERHEAD_ACTION_TYPES } from "./actions";


const initialState = {
    incomingorderData: [],
    isIncomingOrderSuccess: false,
    isIncomingOrderError: false,
  
    isOrderAcceptedSuccess:false,
    isOrderAcceptedError:false,

    isOrderRejectedSuccess:false,
    isOrderRejectedError:false,

    
    isOrderDeliveredSuccess:false,
    isOrderDeliveredError:false,

    
    isgetReviewsSuccess:false,
    isgetReviewsError:false,
    reviewData:[],

    isgetReportsSuccess:false,
    isgetReportsError:false,
    reportsData:[],

    isOrderReadySuccess:false,
    isOrderReadyError:false,


    isgetMenuWiseDaySaleReportsSuccess:false,
    isgetMenuWiseDaySaleReportsError:false,
    menuWiseReportData:[],

    dashboardStats: [],
    isgetDashboardStatsSuccess:false,
    isgetDashboardStatsError:false,

    seatingCountData: [],
    isgetSeatingCountSuccess:false,
    isgetSeatingCountError:false,
    
    isTiffinUserCountSuccess:false,
    isTiffinUserCountError:false,
    
    isLiveOrderStatusSuccess:false,
    isLiveOrderStatusError:false,
    orderStatusData:{},

    deliveredorderData: [],
    isDeliveredOrderSuccess:false,
    isDeliveredOrderError:false,
    
    
    isgetDailySaleSubsidyReportsSuccess:false,
    isgetDailySaleSubsidyReportsError:false,
    dailySaleSubsidyReportData:[]
}

 

export const counterheadReducer = (state = initialState, action) => {
  // console.log(action.type);
  // console.log(action.payload);

  switch (action.type) {
    case COUNTERHEAD_ACTION_TYPES.GET_INCOMING_ORDER_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      incomingorderData: [],
      isIncomingOrderSuccess:false,
      isIncomingOrderError:false 
    };

    case COUNTERHEAD_ACTION_TYPES.GET_INCOMING_ORDER_REQUEST_SUCCESS:
      return { ...state, incomingorderData: action.payload,isIncomingOrderSuccess:true,isIncomingOrderError:false };

    case COUNTERHEAD_ACTION_TYPES.GET_INCOMING_ORDER_REQUEST_ERROR:
      return { ...state, incomingorderData: action.payload,isIncomingOrderSuccess:false,isIncomingOrderError:true };



    case COUNTERHEAD_ACTION_TYPES.ACCEPT_ORDER_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isOrderAcceptedSuccess:false,
      isOrderAcceptedError:false 
    };

    case COUNTERHEAD_ACTION_TYPES.ACCEPT_ORDER_REQUEST_SUCCESS:
      return { ...state, isOrderAcceptedSuccess:true,isOrderAcceptedError:false };

    case COUNTERHEAD_ACTION_TYPES.ACCEPT_ORDER_REQUEST_ERROR:
      return { ...state, isOrderAcceptedSuccess:false,isOrderAcceptedError:true };



    case COUNTERHEAD_ACTION_TYPES.REJECT_ORDER_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isOrderRejectedSuccess:false,
      isOrderRejectedError:false 
    };

    case COUNTERHEAD_ACTION_TYPES.REJECT_ORDER_REQUEST_SUCCESS:
      return { ...state, isOrderRejectedSuccess:true,isOrderRejectedError:false };

    case COUNTERHEAD_ACTION_TYPES.REJECT_ORDER_REQUEST_ERROR:
      return { ...state, isOrderRejectedSuccess:false,isOrderRejectedError:true };


    case COUNTERHEAD_ACTION_TYPES.DELIVER_ORDER_REQUEST:
      // here we catch our payload data and pass it into the state of the store
      return { ...state, 
        isOrderDeliveredSuccess:false,
        isOrderDeliveredError:false 
      };
  
      case COUNTERHEAD_ACTION_TYPES.DELIVER_ORDER_REQUEST_SUCCESS:
        return { ...state, isOrderDeliveredSuccess:true,isOrderDeliveredError:false };
  
      case COUNTERHEAD_ACTION_TYPES.DELIVER_ORDER_REQUEST_ERROR:
        return { ...state, isOrderDeliveredSuccess:false,isOrderDeliveredError:true };


      case COUNTERHEAD_ACTION_TYPES.READY_ORDER_REQUEST:
      // here we catch our payload data and pass it into the state of the store
      return { ...state, 
        isOrderReadySuccess:false,
        isOrderReadyError:false 
      };
  
      case COUNTERHEAD_ACTION_TYPES.READY_ORDER_REQUEST_SUCCESS:
        return { ...state, isOrderReadySuccess:true,isOrderReadyError:false };
  
      case COUNTERHEAD_ACTION_TYPES.READY_ORDER_REQUEST_ERROR:
        return { ...state, isOrderReadySuccess:false,isOrderReadyError:true };


      case COUNTERHEAD_ACTION_TYPES.GET_REVIEWS_REQUEST:
        // here we catch our payload data and pass it into the state of the store
      return { ...state, 
        isgetReviewsSuccess:false,
        isgetReviewsError:false 
      };
  
      case COUNTERHEAD_ACTION_TYPES.GET_REVIEWS_REQUEST_SUCCESS:
        return { ...state, reviewData:action.payload.reviews, isgetReviewsSuccess:true,isgetReviewsError:false };
  
      case COUNTERHEAD_ACTION_TYPES.GET_REVIEWS_REQUEST_ERROR:
        return { ...state, isgetReviewsSuccess:false,isgetReviewsError:true };

            

      case COUNTERHEAD_ACTION_TYPES.GET_REPORTS_REQUEST:
      // here we catch our payload data and pass it into the state of the store
      return { ...state, 
        reportsData: [],
        isgetReportsSuccess:false,
        isgetReportsError:false 
      };
  
      case COUNTERHEAD_ACTION_TYPES.GET_REPORTS_REQUEST_SUCCESS:
        return { ...state, reportsData: action.payload,isgetReportsSuccess:true,isgetReportsError:false };
  
      case COUNTERHEAD_ACTION_TYPES.GET_REPORTS_REQUEST_ERROR:
        return { ...state, reportsData: action.payload,isgetReportsSuccess:false,isgetReportsError:true };

    case COUNTERHEAD_ACTION_TYPES.GET_MENU_WISE_REPORTS_REQUEST:
      // here we catch our payload data and pass it into the state of the store
      return { ...state, 
        menuWiseReportData: [],
        isgetMenuWiseDaySaleReportsSuccess:false,
        isgetMenuWiseDaySaleReportsError:false 
      };
  
    case COUNTERHEAD_ACTION_TYPES.GET_MENU_WISE_REPORTS_REQUEST_SUCCESS:
      return { ...state, menuWiseReportData: action.payload,isgetMenuWiseDaySaleReportsSuccess:true,isgetMenuWiseDaySaleReportsError:false };

    case COUNTERHEAD_ACTION_TYPES.GET_MENU_WISE_REPORTS_REQUEST_ERROR:
        return { ...state, menuWiseReportData: action.payload,isgetMenuWiseDaySaleReportsSuccess:false,isgetMenuWiseDaySaleReportsError:true };



    case COUNTERHEAD_ACTION_TYPES.GET_DASHBOARD_STATS_REQUEST:
      // here we catch our payload data and pass it into the state of the store
      return { ...state, 
        dashboardStats: [],
        isgetDashboardStatsSuccess:false,
        isgetDashboardStatsError:false 
      };
  
    case COUNTERHEAD_ACTION_TYPES.GET_DASHBOARD_STATS_REQUEST_SUCCESS:
      return { ...state, dashboardStats: action.payload,isgetDashboardStatsSuccess:true,isgetDashboardStatsError:false };

    case COUNTERHEAD_ACTION_TYPES.GET_DASHBOARD_STATS_REQUEST_ERROR:
        return { ...state, dashboardStats: action.payload,seatingCountDataisgetDashboardStatsSuccess:false,isgetDashboardStatsError:true };

        
    case COUNTERHEAD_ACTION_TYPES.GET_SEATING_COUNT_REQUEST:
      // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isgetSeatingCountSuccess:false,
      isgetSeatingCountError:false 
    };

    case COUNTERHEAD_ACTION_TYPES.GET_SEATING_COUNT_REQUEST_SUCCESS:
      return { ...state, seatingCountData:action.payload, 
        isgetSeatingCountSuccess:true,isgetSeatingCountError:false };

    case COUNTERHEAD_ACTION_TYPES.GET_SEATING_COUNT_REQUEST_ERROR:
      return { ...state, isgetSeatingCountSuccess:false,isgetSeatingCountError:true };



    case COUNTERHEAD_ACTION_TYPES.UPDATE_TIFFIN_USER_COUNT_REQUEST:
      // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isTiffinUserCountSuccess:false,
      isTiffinUserCountError:false 
    };

    case COUNTERHEAD_ACTION_TYPES.UPDATE_TIFFIN_USER_COUNT_REQUEST_SUCCESS:
      return { ...state, seatingCountData:action.payload, isTiffinUserCountSuccess:true,isTiffinUserCountError:false };

    case COUNTERHEAD_ACTION_TYPES.UPDATE_TIFFIN_USER_COUNT_REQUEST_ERROR:
      return { ...state, isTiffinUserCountSuccess:false,isTiffinUserCountError:true };



      
    case COUNTERHEAD_ACTION_TYPES.GET_LIVE_ORDER_STATUS_REQUEST:
      // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isLiveOrderStatusSuccess:false,
      isLiveOrderStatusError:false,
      orderStatusData:{}
    };

    case COUNTERHEAD_ACTION_TYPES.GET_LIVE_ORDER_STATUS_REQUEST_SUCCESS:
      return { ...state, orderStatusData:action.payload, isLiveOrderStatusSuccess:true,isLiveOrderStatusError:false };

    case COUNTERHEAD_ACTION_TYPES.GET_LIVE_ORDER_STATUS_REQUEST_ERROR:
      return { ...state, isLiveOrderStatusSuccess:false,isLiveOrderStatusError:true };


    case COUNTERHEAD_ACTION_TYPES.GET_DELIVERED_ORDER_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
     deliveredorderData: [],
    isDeliveredOrderSuccess:false,
    isDeliveredOrderError:false 
    };
    case COUNTERHEAD_ACTION_TYPES.GET_DELIVERED_ORDER_REQUEST_SUCCESS:
      return { ...state, deliveredorderData: action.payload,isDeliveredOrderSuccess:true,isDeliveredOrderError:false };
    case COUNTERHEAD_ACTION_TYPES.GET_DELIVERED_ORDER_REQUEST_ERROR:
      return { ...state, deliveredorderData: action.payload,isDeliveredOrderSuccess:false,isDeliveredOrderError:true };
    case COUNTERHEAD_ACTION_TYPES.GET_DAILY_SALE_SUBSIDY_REPORT_REQUEST:
      // here we catch our payload data and pass it into the state of the store
      return { ...state, 
        dailySaleSubsidyReportData:[],
        isgetDailySaleSubsidyReportsSuccess:false,
        isgetDailySaleSubsidyReportsError:false,
      };
  
    case COUNTERHEAD_ACTION_TYPES.GET_DAILY_SALE_SUBSIDY_REPORT_REQUEST_SUCCESS:
      return { ...state, dailySaleSubsidyReportData: action.payload,isgetDailySaleSubsidyReportsSuccess:true,isgetDailySaleSubsidyReportsError:false };
    case COUNTERHEAD_ACTION_TYPES.GET_DAILY_SALE_SUBSIDY_REPORT_REQUEST_ERROR:
        return { ...state, isgetDailySaleSubsidyReportsSuccess:false,isgetDailySaleSubsidyReportsError:true };

    default:
      return state
  }
}