import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  useIonViewWillEnter,
  useIonToast,
  useIonLoading,
  IonLoading,
  useIonAlert,
} from "@ionic/react";
import "./Login.scss";
import { ACTION_TYPES } from "../redux/user/orders/actions";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import AuthHeader from "../components/AuthHeader";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface PaymentProcessingProps extends OwnProps, DispatchProps {
  payOrderOnlineAction: Function;
  isPayOrderOnlineSuccess: boolean;
  isPayOrderOnlineError: boolean;
  payOrderOnlineSodexoAction: Function;
}

const PaymentProcessing: React.FC<PaymentProcessingProps> = ({
  payOrderOnlineAction,
  isPayOrderOnlineSuccess,
  isPayOrderOnlineError,
  history,
  payOrderOnlineSodexoAction,
}) => {
  const [messageToast] = useIonToast();
  const [present, dismiss] = useIonLoading();
  const [showLoading, setShowLoading] = useState(false);
  const [presentAlert] = useIonAlert();

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };

  const validatePayment = (
    success: any,
    orderId: any,
    type: string,
    reason: any
  ) => {
    // alert("validatePayment...............................");
    if (type == "paytmSuccess" && success == 1 && orderId != null) {
      setShowLoading(true);
      payOrderOnlineAction({
        orderId: Number(orderId),
      });
    } else if (type == "sodaxoSuccess" && success == 1 && orderId != null) {
      setShowLoading(true);
      payOrderOnlineSodexoAction({
        orderId: Number(orderId),
      });
    } else if (type == "sodaxoFailure" && orderId != null) {
      presentAlert({
        header: "Payment Failed, try again later.",
        cssClass: "custom-alert",
        buttons: [
          {
            text: "OK",
            role: "confirm",
            handler: () => {
              history.push("/dashboard", { direction: "none" });
            },
          },
        ],
      });

      // sodaxoFailure=1&orderId=48?reason=CANCELLED_BY_USER_AGENT&q=NYRA_DEV_Order_48
    } else {
      presentAlert({
        header: "Payment Failed, try again later.",
        cssClass: "custom-alert",
        buttons: [
          {
            text: "OK",
            role: "confirm",
            handler: () => {
              history.push("/dashboard", { direction: "none" });
            },
          },
        ],
      });
    }
  };

  useIonViewWillEnter(() => {
    // setShowLoading(true);

    const queryParameters = new URLSearchParams(window.location.search);
    const sodaxoSuccess = queryParameters.get("sodaxoSuccess");

    const paytmSuccess = queryParameters.get("paytmSuccess");
    let orderId = queryParameters.get("orderId");
    // console.log(queryParameters.get("orderId").slice("?")[0]);
    if (orderId?.includes("?")) {
      orderId = orderId?.split("?")[0];
    } else {
    }
    const sodaxoFailure = queryParameters.get("sodaxoFailure");

    const reason = queryParameters.get("reason");

    if (orderId?.includes("?")) {
      orderId = orderId?.split("?")[0];
    } else {
    }
    // const paytmTxnToken = queryParameters.get("paytmTxnToken");

    if (sodaxoSuccess) {
      validatePayment(sodaxoSuccess, orderId, "sodaxoSuccess", "");
    } else if (paytmSuccess) {
      validatePayment(paytmSuccess, orderId, "paytmSuccess", "");
    } else if (sodaxoFailure) {
      console.log("111", sodaxoFailure);

      validatePayment(sodaxoFailure, orderId, "sodaxoFailure", reason);
    }
  });

  useEffect(() => {
    if (isPayOrderOnlineSuccess) {
      setShowLoading(false);
      presentToast("top", "Payment paid successfully.");
      setTimeout(() => {
        history.push("/dashboard", { direction: "none" });
      }, 2000);
    }
  }, [isPayOrderOnlineSuccess]);

  useEffect(() => {
    if (isPayOrderOnlineError) {
      setShowLoading(false);
      presentToast("top", "Payment failed , Please try again.");
      setTimeout(() => {
        history.push("/dashboard", { direction: "none" });
      }, 2000);
    }
  }, [isPayOrderOnlineError]);

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait while payment is processing..."}
          // duration={5000}
        />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isPayOrderOnlineSuccess: state.orders.isPayOrderOnlineSuccess,
  isPayOrderOnlineError: state.orders.isPayOrderOnlineError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    payOrderOnlineAction: (orderDetails: boolean) =>
      dispatch({
        type: ACTION_TYPES.PAY_ORDER_ONLINE_REQUEST,
        payload: orderDetails,
      }),
    payOrderOnlineSodexoAction: (orderDetails: boolean) =>
      dispatch({
        type: ACTION_TYPES.PAY_ORDER_ONLINE_SODEXO_REQUEST,
        payload: orderDetails,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentProcessing));
